import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { normalizeBooleanAttribute } from '~lib/helpers';

export interface ICounter {
  color?: string;
  value: number | string | undefined;
  position?: CounterPosition;
}

export type CounterPosition = 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';

/**
 * Alineación de los counters del botón.
 */
export type CounterAlign = 'corners' | 'stack';

/**
 * Muestra un ícono y un label (opcional), con efectos de ion-activable y ripple-effect.
 *
 * Variables CSS:
 *
 * --padding: 2px;
 * --icon-size: 3em;
 * --border-width: 0; // Por defecto sin borde
 */
@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {
  private _counters: ICounter[] = [];

  @Input()
  color: string | undefined;
  private _disabled = false;

  @HostBinding('class.button-disabled') // Agrega la clase .button-disabled al componente base según esté deshabilitado o no
  @Input()
  set disabled(value: boolean | string | undefined) {
    this._disabled = normalizeBooleanAttribute(value);
  }
  get disabled() {
    return this._disabled;
  }

  @Input()
  counterAlign: CounterAlign = 'corners';

  @Input()
  counterPositionsOrder: CounterPosition[] = ['top-right', 'bottom-right', 'bottom-left', 'top-left'];

  @Input()
  set counters(counters: ICounter[]) {
    // console.log(counters);
    const positions = [...this.counterPositionsOrder];

    this._counters = counters
      .filter((counter) => counter.value !== undefined)
      .map((counter) => {
        if (!counter.position) {
          let pos: CounterPosition | undefined;

          while (positions.length > 0 && (pos === undefined || counters.findIndex((c) => c.position === pos) !== -1)) {
            pos = positions.shift();
            // console.log({ positions, pos });
          }

          // console.log(pos);

          counter.position = pos;
        }
        // console.log(counter);

        return counter;
      });
  }

  get counters(): ICounter[] {
    return this._counters;
  }

  /**
   * @deprecated Usar `counters` con su propiedad `color`.
   */
  @Input()
  counterColor = 'danger';

  /**
   * @deprecated Usar `counters` con su propiedad `value`.
   */
  @Input()
  counter: number | undefined;

  @Input()
  icon: IconProp | undefined;

  constructor() {
    //
  }

  ngOnInit() {
    //
  }
}
