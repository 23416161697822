import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

import { Area } from './area';
import { ICategoryData } from './category-data.interface';

export class Category extends Model<ICategoryData> {
  public static type = 'categories';

  constructor(data: Partial<ICategoryData>, id: string | null = null, parentPath?: string) {
    const defaultData: ICategoryData = {
      teamId: '',
      name: '',
      description: null,
      image: null,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
      areaId: null,
      promo: '',
      offer: '',
      offercodeApplicable: '',
      id,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    const catId = id || data.id || null;

    if (!parentPath && safeData.teamId && catId) {
      parentPath = `${safeData.teamId}/categories/${catId}`;
    }

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  private _area$: Observable<Area | null> | undefined;

  get area(): Observable<Area | null> {
    if (!this._area$) {
      this._area$ = new Area({}, this.data.areaId)
        .odm()
        .doc()
        .get()
        .pipe(
          map((snapshot) => {
            const data = snapshot.data();

            if (!snapshot.exists || !data) {
              return null;
            }

            return new Area(data, this.data.areaId);
          })
        );
    }

    return this._area$;
  }
}
