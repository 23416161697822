import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationDocument } from '~/database/models/notification';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  @Input()
  notification!: NotificationDocument;

  @Output()
  public readonly touch = new EventEmitter<{ notification: NotificationDocument, markAsRead: () => Promise<void> }>(true);

  constructor() {
    //
  }

  ngOnInit() {
    //
  }

  onClick() {
    this.touch.emit({
      notification: this.notification,
      markAsRead: async () => {
        await this.markAsRead();
      },
    });
  }

  async markAsRead() {
    if (!this.notification.readAt) {
      await this.notification.markAsRead();
    }
  }
}
