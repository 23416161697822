import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedDirectivesModule } from '~shared/directives/shared-directives.module';
import { AddToCartModalComponent } from './add-to-cart-modal/add-to-cart-modal.component';
import { QrCodeModalComponent } from './qr-code-modal/qr-code-modal.component';
import { RatingModalComponent } from './rating-modal/rating-modal.component';
import { AddToCartCustomerModalComponent } from './add-to-cart-customer-modal/add-to-cart-customer-modal.component';

import { BackButtonFallbackComponent } from './back-button-fallback/back-button-fallback.component';
import { ContextCardComponent } from './context-card/context-card.component';
import { CustomerItemComponent } from './customer-item/customer-item.component';
import { ElapsedTimeCounterComponent } from './elapsed-time-counter/elapsed-time-counter.component';
import { FormInputComponent } from './form-input/form-input.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { OrderCardComponent } from './order-card/order-card.component';
import { OrderCardCustomerComponent } from './order-card-customer/order-card-customer.component';
import { BookingCardCustomerComponent } from './booking-card-customer/booking-card-customer.component';
import { OrderProductItemComponent } from './order-product-item/order-product-item.component';
import { OrderProductsModalComponent } from './order-products-modal/order-products-modal.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { ProductItemComponent } from './product-item/product-item.component';
import { CategoryCardComponent } from './category-card/category-card.component';
import { ProductsFilteringModalComponent } from './products-filtering-modal/products-filtering-modal.component';
import { SectionCardComponent } from './section-card/section-card.component';
import { CustomerSectionCardComponent } from './customer-section-card/customer-section-card.component';
import { AccordionComponent } from './accordion/accordion.component';
import {IonicRatingComponent} from 'ionic-rating-component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    MenuItemComponent,
    FormInputComponent,
    SectionCardComponent,
    CustomerSectionCardComponent,
    OrderProductItemComponent,
    AddToCartModalComponent,
    QrCodeModalComponent,
    RatingModalComponent,
    AccordionComponent,
    AddToCartCustomerModalComponent,
    OrderCardComponent,
    OrderCardCustomerComponent,
    BookingCardCustomerComponent,
    OrderProductsModalComponent,
    PaymentModalComponent,
    IconButtonComponent,
    BackButtonFallbackComponent,
    NotificationItemComponent,
    CustomerItemComponent,
    ProductItemComponent,
    CategoryCardComponent,
    ProductsFilteringModalComponent,
    ContextCardComponent,
    ElapsedTimeCounterComponent,
    IonicRatingComponent
    //
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    FontAwesomeModule,
    TranslateModule,
    RouterModule,

    //
    SharedDirectivesModule,
  ],
  exports: [
    MenuItemComponent,
    FormInputComponent,
    SectionCardComponent,
    AccordionComponent,
    CustomerSectionCardComponent,
    OrderProductItemComponent,
    OrderCardComponent,
    OrderCardCustomerComponent,
    BookingCardCustomerComponent,
    IconButtonComponent,
    BackButtonFallbackComponent,
    NotificationItemComponent,
    CustomerItemComponent,
    ProductItemComponent,
    CategoryCardComponent,
    ContextCardComponent,
    ElapsedTimeCounterComponent,

    //
    SharedDirectivesModule,
    TranslateModule,

    //

    FontAwesomeModule,
  ],
})
export class SharedComponentsModule {}
