import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { difference } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FeatureSlug } from '~/database/models/feature';
import { ActivePlanService } from '~shared/services/active-plan.service';

/* tslint:disable:directive-selector */

/**
 * Directiva para mostrar un elemento dependiendo de si al menos una funcionalidad está habilitada.
 *
 * Use 'or: true' to always display the view and `and: false` to do not display the template, regardless of features.
 *
 * @example Usar en cualquier elemento, similar a `*ngIf`, con la lista de slugs de funciones:
 *  `*ifHasAnyFeatures="['customer:interactive-menu', 'customer:preview-menu']"`
 *
 * @example Puede conbinarse con un condicional and y or:
 *  `*ifHasAnyFeatures="['customer:interactive-menu', 'customer:preview-menu']; or: isSuperAdmin"`
 *  `*ifHasAnyFeatures="['customer:interactive-menu', 'customer:preview-menu']; and: planIsActive"`
 *
 * @example Puede usarse con else, pasando un template, como if-else:
 *  `*ifHasAnyFeatures="[[customer:interactive-menu', 'customer:preview-menu']; else whenHasNotFeatureTemplate"`
 */
@Directive({
  selector: '[ifHasAnyFeatures]',
})
export class IfHasAnyFeaturesDirective implements OnInit, OnDestroy {
  private slugs: FeatureSlug[] = [];
  private readonly hasFeaturesSubject = new BehaviorSubject<boolean | undefined>(undefined);
  protected readonly hasFeatures$: Observable<boolean | undefined>;

  private usingElseTemplate = false;

  get hasFeatures() {
    return this.hasFeaturesSubject.value;
  }

  @Input()
  set ifHasAnyFeatures(slugs: FeatureSlug[]) {
    const areDifferent = this.slugs.length !== slugs.length || difference(this.slugs, slugs).length > 0;

    if (!areDifferent) {
      return;
    }

    this.slugs = slugs;

    if (this.changeSlugsSubscription && !this.changeSlugsSubscription.closed) {
      this.changeSlugsSubscription.unsubscribe();
    }

    this.changeSlugsSubscription = this.activePlan.hasAnyFeature$(this.slugs).subscribe((hasFeatures) => {
      // console.log('changeSlugsSubscription', this.slugs, { hasFeatures });

      if (this.hasFeatures !== hasFeatures) {
        this.hasFeaturesSubject.next(hasFeatures);
      }
    });
  }

  protected changeSlugsSubscription: Subscription | undefined;
  protected updateViewSubscription: Subscription | undefined;

  private readonly orBehaviorSubject = new BehaviorSubject(false);
  protected or$: Observable<boolean>;

  /**
   * Si este valor se establece en `true`, ignora si tiene la función o no; muestra la vista.
   */
  @Input()
  set ifHasAnyFeaturesOr(or: boolean) {
    this.orBehaviorSubject.next(or);
  }
  get ifHasAnyFeaturesOr(): boolean {
    return this.orBehaviorSubject.value;
  }

  private readonly andBehaviorSubject = new BehaviorSubject(true);
  protected and$: Observable<boolean>;

  /**
   * Si este valor se establece en `false`, ignora si tiene la función o no; NO muestra la vista.
   */
  @Input()
  set ifHasAnyFeaturesAnd(and: boolean) {
    this.andBehaviorSubject.next(and);
  }
  get ifHasAnyFeaturesAnd(): boolean {
    return this.andBehaviorSubject.value;
  }

  @Input()
  ifHasAnyFeaturesElse: TemplateRef<any> | undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private activePlan: ActivePlanService
  ) {
    this.hasFeatures$ = this.hasFeaturesSubject.asObservable().pipe(shareReplay(1));
    this.or$ = this.orBehaviorSubject.asObservable().pipe(shareReplay(1));
    this.and$ = this.andBehaviorSubject.asObservable().pipe(shareReplay(1));
    //
  }

  ngOnDestroy(): void {
    if (this.changeSlugsSubscription && !this.changeSlugsSubscription.closed) {
      this.changeSlugsSubscription.unsubscribe();
    }

    if (this.updateViewSubscription && !this.updateViewSubscription.closed) {
      this.updateViewSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.updateViewSubscription = combineLatest([this.and$, this.or$, this.hasFeatures$])
      .pipe(map(([and, or, hasFeatures]) => and && (or || hasFeatures)))
      .subscribe((canView) => {
        // console.log('updateViewSubscription', canView, this.viewContainer.length, this.embeddedView);

        if (canView) {
          if (this.viewContainer.length === 0 || this.usingElseTemplate) {
            if (this.usingElseTemplate) {
              this.viewContainer.clear();
            }

            this.viewContainer.createEmbeddedView(this.templateRef);
            this.usingElseTemplate = false;
          }
        } else {
          if (this.viewContainer.length !== 0 || !this.usingElseTemplate) {
            this.viewContainer.clear();

            if (this.ifHasAnyFeaturesElse) {
              this.viewContainer.createEmbeddedView(this.ifHasAnyFeaturesElse);
              this.usingElseTemplate = true;
            }
          }
        }
      });
  }
}
