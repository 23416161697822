import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrderProduct } from '~/database/models/order-product';
import { IOrderProductData } from '~/database/models/order-product-data.interface';
import { Product } from '~/database/models/product';

export type OrderItem = { product?: Observable<Product | null> } & Pick<
  IOrderProductData,
  'price' | 'quantity' | 'notes' | 'offerApplicable' | 'promocode' | 'promoquantity'
>;

@Component({
  selector: 'app-order-products-modal',
  templateUrl: './order-products-modal.component.html',
  styleUrls: ['./order-products-modal.component.scss'],
})
export class OrderProductsModalComponent implements OnInit {
  #orderProducts: OrderProduct[] = [];
  #orderTeamId:string='';

  @Input()
  set orderTeamId(orderTeamId) {
    this.#orderTeamId = orderTeamId
  }

  @Input()
  set orderProducts(products) {
    
    this.#orderProducts = products;

    
  }
  get orderTeamId() {
     return this.#orderTeamId;
  }
  get orderProducts() {
   
    return this.#orderProducts;
  }


  items: OrderItem[] = [];
  total = 0;

  constructor(public modalController: ModalController) {}

  ngOnInit() {
    this.total = 0;
    this.items = this.#orderProducts.map((orderProduct) => {
      const item: OrderItem = {
        price: +orderProduct.data.price,
        quantity: orderProduct.data.quantity,
        notes: orderProduct.data.notes,
        offerApplicable: orderProduct.data.offerApplicable,
        promocode: orderProduct.data.promocode,
        promoquantity: orderProduct.data.promoquantity,
      };
     
      const ref = orderProduct.restaurantRef();
     
      if (ref) {
        const p = new Product({}, orderProduct.data.productId, ref.path);

        const p$ = p
          .odm()
          .doc()
          .get()
          .pipe(
            map((r) => {
              const data = r.data();
              // console.log(r);

              if (!data) {
                return null;
              }

              return new Product(data, r.id, ref.path);
            })
          );

        item.product = p$;
      }else{
        
        if(this.orderTeamId){
          const p = new Product({}, orderProduct.data.productId, `restaurants/${this.#orderTeamId}`);
          //restaurants/${restaurant.id}/order/${data.id}
        const p$ = p
          .odm()
          .doc()
          .get()
          .pipe(
            map((r) => {
              const data = r.data();
              // console.log(r);

              if (!data) {
                return null;
              }

              return new Product(data, r.id, `restaurants/${this.#orderTeamId}`);
            })
          );

        item.product = p$;
        }
      }
      if (item.promoquantity) {
        this.total += +item.price * item.promoquantity;
      } else {
        this.total += +item.price * item.quantity;
      }

      return item;
    });
  }

  public dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }
}
