import { Component, Input, OnInit } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TableSessionUser } from '~/database/models/table-session-user';
import { User } from '~/database/models/user';

/**
 * Muestra la información de un cliente.
 */
@Component({
  selector: 'app-customer-item',
  templateUrl: './customer-item.component.html',
  styleUrls: ['./customer-item.component.scss'],
})
export class CustomerItemComponent implements OnInit {
  private _customer: User | TableSessionUser | null | undefined;

  isCompanion: boolean | undefined;
  userIcon: IconProp | undefined;

  companion: TableSessionUser | null | undefined;
  mainCustomer: User | null | undefined;

  @Input()
  set customer(customer: User | TableSessionUser | null | undefined) {
    if (customer instanceof TableSessionUser) {
      this.mainCustomer = null;
      this.companion = customer;

      this.isCompanion = true;
      this.userIcon =
        customer.data.enabled === null
          ? ['fas', 'user-clock']
          : customer.data.enabled
          ? ['fas', 'user-check']
          : ['fas', 'user-times'];
    } else if (customer instanceof User) {
      this.mainCustomer = customer;
      this.companion = null;

      this.isCompanion = false;
      this.userIcon = ['fas', 'user-alt'];
    } else {
      this.mainCustomer = undefined;
      this.companion = undefined;
      this.isCompanion = undefined;
      this.userIcon = undefined;
    }

    // console.log(customer);

    this._customer = customer;
  }
  get customer() {
    return this._customer;
  }

  constructor() {}

  ngOnInit() {}
}
