import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Order } from '~/database/models/order';
import { OrderProduct } from '~/database/models/order-product';
import { IOrderStatusData, OrderStatus } from '~/database/models/order-status';
import { AuthenticationService } from '~shared/services/authentication.service';
import { OverlayEventDetail } from '@ionic/core';
import { OrderProductsModalComponent } from '../order-products-modal/order-products-modal.component';
import { RatingModalComponent } from '../rating-modal/rating-modal.component';
import { AddReviewData } from '../rating-modal/rating-modal.component';
import {
  LaravelApiService,
 resultIsCollection,
 UriRoute,
} from '~shared/services/laravel-api.service';
 
import { Booking } from '~/database/models/booking';
@Component({
  selector: 'app-booking-card-customer',
  templateUrl: './booking-card-customer.component.html',
  styleUrls: ['./booking-card-customer.component.scss'],
})
export class BookingCardCustomerComponent implements OnInit, OnDestroy {
 
  currentUserType:string | null | undefined;
  @Input()
  booking: Booking | undefined;

   

  
  @Output()
  public readonly cardClick = new EventEmitter<string>();

  @Output()
  public readonly confirmClick = new EventEmitter<string>();


  /**
   * Permite detectar si hay algún hadler del evento cardClick asignado.
   */
  get hasCardClickHandlers() {
    // console.log('🚧', this.clicked.observers.length);
    return this.cardClick.observers.length > 0;
  }

  constructor(
    private modalController: ModalController,
    private api: LaravelApiService,
    private auth:AuthenticationService ) {
     
      
    this.auth.currentUserInstance$.subscribe((user)=>{
      this.currentUserType = user?.data.regType;
    })
  
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    
  }

 
  

 
  
  onCardClick() {
    const bookingId = this.booking?.id;
    // console.log('⭐ onCardClick()');

    if (!bookingId) {
      return;
    }
    
    this.cardClick.emit(bookingId);
  }


  onConfirmClick(ev: Event){
    ev.stopPropagation();
    const bookingId = this.booking?.id;
    if (!bookingId) {
      return;
    }
    this.confirmClick.emit(bookingId);
  }
  onOrderStatusClick(ev: Event) {
    ev.stopPropagation();
  }
}
