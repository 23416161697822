import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { normalizeBooleanAttribute } from '~lib/helpers';

/**
 * Muestra un IonCard, pero con un área a la izquierda para agregar un fondo distinto y, opcionalmente, un ícono.
 *
 * Permite variables CSS para poder personalizarse:
 * --context-column-size: 1.25em;
 * --context-icon-size: calc(var(--context-column-size) - 4px);
 * --ion-card-margin: 5px;
 * --ion-card-padding: 4px;
 */
@Component({
  selector: 'app-context-card',
  templateUrl: './context-card.component.html',
  styleUrls: ['./context-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextCardComponent implements OnInit {
  private _button = false;
  private _disabled = false;

  @Input()
  contextColor: string | undefined;

  @Input()
  color: string | undefined;

  @Input()
  faIcon: IconProp | undefined;

  @Input()
  set button(value: boolean | string | undefined) {
    this._button = normalizeBooleanAttribute(value);
  }
  get button() {
    return this._button;
  }

  @Input()
  set disabled(value: boolean | string | undefined) {
    this._disabled = normalizeBooleanAttribute(value);
  }
  get disabled() {
    return this._disabled;
  }

  @Output()
  public readonly clicked = new EventEmitter();

  /**
   * Permite detectar si hay algún hadler del evento clicked asignado.
   */
  get hasClickedHandlers() {
    // console.log('🚧', this.clicked.observers.length);
    return this.clicked.observers.length > 0;
  }

  constructor() {}

  ngOnInit() {}

  onClicked(ev: Event) {
    this.clicked.emit(ev);
  }
}
