import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  /**
   */
  protected readonly schemeSubject: BehaviorSubject<'dark' | 'light'>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado (sucede cuando se cambia/inicializa el Restaurant).
   */
  public readonly scheme$: Observable<'dark' | 'light'>;

  /**
   * Si el valor es undefined, significa que aún no se ha inicializado.
   */
  public get scheme(): 'dark' | 'light' {
    return this.schemeSubject.value;
  }
  public set scheme(scheme: 'dark' | 'light') {
    this.schemeSubject.next(scheme);
  }

  public get isDark() {
    return this.scheme === 'dark';
  }
  public set isDark(isDark: boolean) {
    this.scheme = isDark ? 'dark' : 'light';
  }

  constructor() {
    this.schemeSubject = new BehaviorSubject<'dark' | 'light'>('light');
    this.scheme$ = this.schemeSubject.asObservable().pipe(shareReplay(1));
  }

  /**
   * Intercambia entre los 2 esquemas de colores.
   */
  toggle() {
    if (this.scheme === 'dark') {
      this.scheme = 'light';
    } else {
      this.scheme = 'dark';
    }
  }
}
