import { Component, HostListener, Input, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import _ from 'lodash-es';
import { ModalController, NavController } from '@ionic/angular';
import { Product } from '~/database/models/product';
import { trigger, state, style, animate, transition, query, animateChild } from '@angular/animations';
import { SessionService } from '~shared/services/session.service';
import { AuthenticationService } from '~shared/services/authentication.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
export interface AddToCartCustomerData {
  quantity: number;
  price: number;
  product: Product;
  notes: string;
  offerApplicable: boolean;
  promocode: string;
  promoquantity: number;
}

@Component({
  selector: 'app-modal [view="add-to-cart-customer"]',
  templateUrl: './add-to-cart-customer-modal.component.html',
  styleUrls: ['./add-to-cart-customer-modal.component.scss'],
  animations: [
    trigger('ngIfAnimation', [transition(':enter, :leave', [query('@*', animateChild())])]),
    trigger('easeInOut', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class AddToCartCustomerModalComponent implements OnInit {
  adding = false;
  #quantity = 1;
  form: FormGroup;
  promotiondata: any;
  promotion: any;
  promocodeApplicable = false;
  promo_code = '';
  finalQuantity = 0;
  userId = '';
  showDescription: boolean = false;
  weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public get max() {
    // FIXME: También tomar en cuenta si ya este mismo producto ya está en el carrito para el stock.
    return this.product.availableStock;
  }

  @Input()
  editing = false;

  @Input()
  isAttendant = false;

  @Input()
  public categories = '';

  @Input()
  public restaurant = '';

  @Input()
  public notes = '';

  @Input()
  public product!: Product;

  @Input()
  set quantity(value) {
    if (value > this.max) {
      this.#quantity = this.max;
    }

    this.#quantity = value;
  }

  get quantity() {
    return this.#quantity;
  }
  get price() {
    return +this.product.data.price;
  }
  get newprice() {
    //  const promo = JSON.parse(JSON.stringify(this.promotiondata));
    this.promotion = this.product;
    const promo = this.promotion.rawData.promotionId;
    if (promo.type == 'percent') {
      return this.product.data.price - (this.product.data.price * promo.percentage) / 100;
    } else if (promo.type == 'amount') {
      return this.product.data.price - promo.discountAmount;
    } else {
      return +this.product.data.price;
    }
  }

  get total() {
    this.promotion = this.product;
    const pr = this.promotion.rawData.promotionId;

    const promo = _.mapKeys(pr, (v, k) => _.camelCase(k));

    // const promo = JSON.parse(JSON.stringify(this.promotiondata));
    if (this.promocodeApplicable) {
      if (promo.type == 'percent') {
        return (this.product.data.price - (this.product.data.price * promo.percentage) / 100) * this.quantity;
      }
      if (promo.type == 'amount') {
        return (this.product.data.price - promo.discountAmount) * this.quantity;
      }
      if (promo.type == 'quantity') {
        console.log(promo.type);
        if (this.quantity == promo.firstQuantity) {
          this.finalQuantity = promo.secondQuantity;

          return this.product.data.price * promo.secondQuantity;
        } else if (this.quantity > promo.firstQuantity) {
          const remainder = this.quantity % promo.firstQuantity;
          const quotient = Math.round((this.quantity - remainder) / promo.firstQuantity);
          this.finalQuantity = quotient * promo.secondQuantity + remainder;

          return this.product.data.price * this.finalQuantity;
        } else {
          this.finalQuantity = this.quantity;

          return this.product.data.price * this.finalQuantity;
        }
      }
    } else {
      return this.price * this.quantity;
    }
  }

  
  
  constructor(
    public modalController: ModalController ,
    private zone: NgZone, 
    private navCtrl: NavController,
    private auth: AuthenticationService,
    private router:Router
    ) {
      console.log(this.auth.currentUser?.uid)
      if(this.auth.currentUser?.uid){
        this.userId = this.auth.currentUser?.uid;
      }
    this.form = new FormGroup({
      notes: new FormControl(),
    });
  }
  showDescriptionArea() {
    this.showDescription = !this.showDescription;
  }
  add_zero(num: number): string {
    if (num < 9) return '0o' + num;
    else return '' + num;
  }
  compareTime(str2: string, str1: string) {
    if (str1 === str2) {
      return 0;
    }
    var time1 = str1.split(':');
    var time2 = str2.split(':');
    if (eval(time1[0]) > eval(time2[0])) {
      return 1;
    } else if (eval(time1[0]) == eval(time2[0]) && eval(time1[1]) >= eval(time2[1])) {
      return 1;
    } else {
      return -1;
    }
  }
  ngOnInit() {
    this.promotiondata = JSON.parse(JSON.stringify(this.product.data.promotionId));
    this.promotion = this.product;
    const promo1 = this.promotion.rawData.promotionId;

    if (promo1 && promo1.code != '' && promo1.code != undefined) {
      this.promo_code = promo1.label;
      const array = JSON.parse(promo1.time);
      const d = new Date();
      const currentDay = this.weekday[d.getDay()];
      const currentTime = d.getHours() + ':' + d.getMinutes();
      this.promocodeApplicable = false;
      array.forEach((value: any) => {
        const start_time = value.Start.hours + ':' + value.Start.minutes;
        const end_time = value.End.hours + ':' + value.End.minutes;
        /* console.log('start_time=' + start_time, 'currentTime=' + currentTime, 'end_time=' + end_time);
        console.log(this.compareTime(currentTime, start_time));
        console.log(this.compareTime(end_time, currentTime));
        console.log(currentDay, value.Day);*/
        if (
          currentDay == value.Day &&
          this.compareTime(start_time, currentTime) >= 0 &&
          this.compareTime(currentTime, end_time) >= 0
        ) {
          this.promocodeApplicable = true;
        }
      });
    }
    if (promo1 && promo1.code == 'undefined') {
      this.promocodeApplicable = false;
    }
    console.log(this.promocodeApplicable);
  }

  public dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }

  public confirm() {
    const searchValue = this.form.controls.notes.value;
    this.notes = searchValue;
    if (this.promocodeApplicable) {
      const data: AddToCartCustomerData = {
        quantity: this.quantity,
        price: this.newprice,
        product: this.product,
        notes: searchValue,
        offerApplicable: this.promocodeApplicable,
        promocode: this.promo_code,
        promoquantity: this.finalQuantity,
      };
      this.modalController.dismiss(data, 'submit');
    } else {
      const data: AddToCartCustomerData = {
        quantity: this.quantity,
        price: this.price,
        product: this.product,
        notes: searchValue,
        offerApplicable: this.promocodeApplicable,
        promocode: '',
        promoquantity: 0,
      };
      this.modalController.dismiss(data, 'submit');
    }
  }
  toggleButton(){
    this.adding = !this.adding;
  }
  
 async toggleAdd() {
    //this.adding = !this.adding;
    this.modalController.dismiss();
    this.zone.runOutsideAngular(() => {
      setTimeout(async() => {
        window.location.href = `${environment.laravel.api.mainUrl}auth/registration/login/aerosmith`;
      }, 25);
    });

   
  }

  public toggle(event: any) {
    if (event.target.classList.contains('selected')) {
      event.target.classList.remove('selected');
      event.target.parentElement.classList.remove('selected');
    } else {
      event.target.classList.add('selected');
      event.target.parentElement.classList.add('selected');
    }
    // console.log(event.target);
  }

  public increaseQuantity(quantity = 1) {
    if (this.quantity <= this.max - quantity) {
      this.quantity += quantity;
    }
  }

  public decreaseQuantity(quantity = 1) {
    if (this.quantity > 1) {
      this.quantity -= quantity;
    }
  }
}
