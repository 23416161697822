import { QueryFn } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

import { NotificationDocument } from './notification';
import { INotificationData } from './notification-data.interface';
import { Order } from './order';
import { IOrderData } from './order-data.interface';
import { ITableSessionData } from './table-session-data.interface';
import { TableSessionUser } from './table-session-user';
import { ITableSessionUserData } from './table-session-user-data.interface';

/**
 * Representa una mesa en la base de datos.
 */
export class TableSession extends Model<ITableSessionData> {
  public static type = 'table_sessions';

  constructor(data: Partial<ITableSessionData>, id: string | null = null, parentPath?: string) {
    const defaultData: ITableSessionData = {
      id: null,
      tableId: '',
      attendantId: null,
      closed: false,
      createdAt: null,
      deletedAt: null,
      updatedAt: null,
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  private _unreadNotifications$: Observable<NotificationDocument[]> | undefined;

  get unreadNotifications$() {
    const notificationParentPath = this.odm().doc().ref.path;

    if (!this._unreadNotifications$) {
      this._unreadNotifications$ = this.odm()
        .child<INotificationData>('notifications', (ref) => {
          return ref.where('readAt', '==', null).limit(99);
        })
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            return snaps.map(({ payload }) => {
              return new NotificationDocument(payload.doc.data(), payload.doc.id, notificationParentPath);
            });
          })
        );
    }

    return this._unreadNotifications$;
  }

  private _unreadAttendantNotifications$: Observable<NotificationDocument[]> | undefined;

  get unreadAttendantNotifications$() {
    if (!this._unreadAttendantNotifications$) {
      this._unreadAttendantNotifications$ = this.odm()
        .child<INotificationData>('notifications', (ref) => {
          return ref.where('context', '==', 'attendants').where('readAt', '==', null).limit(99);
        })
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            return snaps.map(({ payload }) => {
              return NotificationDocument.fromPayloadDocument(payload.doc);
            });
          })
        );
    }

    return this._unreadAttendantNotifications$;
  }

  private _orders$: Observable<Order[]> | undefined;
  get orders$() {
    if (!this._orders$) {
      this._orders$ = this.getOrders$((ref) => {
        return ref.orderBy('updatedAt', 'desc');
      });
    }

    return this._orders$;
  }

  private _companions$: Observable<TableSessionUser[]> | undefined;
  get companions$() {
    if (!this._companions$) {
      this._companions$ = this.companions().all();
    }

    return this._companions$;
  }

  private _enabledCompanions$: Observable<TableSessionUser[]> | undefined;
  get enabledCompanions$() {
    if (!this._enabledCompanions$) {
      this._enabledCompanions$ = this.companions().enabled();
    }

    return this._enabledCompanions$;
  }

  /**
   * Gets the filtering scopes for companions of this session.
   */
  companions() {
    /**
     * Scopes.
     */
    const scopes = {
      all: () => {
        return this.odm()
          .child<ITableSessionUserData>('users')
          .snapshotChanges()
          .pipe(
            map((snaps) => {
              return snaps.map(({ payload }) => {
                return TableSessionUser.fromPayloadDocument(payload.doc);
              });
            })
          );
      },
      enabled: () => {
        return this.odm()
          .child<ITableSessionUserData>('users', (ref) => {
            return ref.where('enabled', '==', true);
          })
          .snapshotChanges()
          .pipe(
            map((snaps) => {
              return snaps.map(({ payload }) => {
                return TableSessionUser.fromPayloadDocument(payload.doc);
              });
            })
          );
      },
      disabled: () => {
        return this.odm()
          .child<ITableSessionUserData>('users', (ref) => {
            return ref.where('enabled', '==', false);
          })
          .snapshotChanges()
          .pipe(
            map((snaps) => {
              return snaps.map(({ payload }) => {
                return TableSessionUser.fromPayloadDocument(payload.doc);
              });
            })
          );
      },
      pending: () => {
        return this.odm()
          .child<ITableSessionUserData>('users', (ref) => {
            return ref.where('enabled', '==', null);
          })
          .snapshotChanges()
          .pipe(
            map((snaps) => {
              return snaps.map(({ payload }) => {
                return TableSessionUser.fromPayloadDocument(payload.doc);
              });
            })
          );
      },
      notPending: () => {
        return this.odm()
          .child<ITableSessionUserData>('users', (ref) => {
            return ref.where('enabled', '!=', null);
          })
          .snapshotChanges()
          .pipe(
            map((snaps) => {
              return snaps.map(({ payload }) => {
                return TableSessionUser.fromPayloadDocument(payload.doc);
              });
            })
          );
      },
    };

    return scopes;
  }

  getOrders$(filters?: QueryFn<IOrderData>) {
    const orders$ = this.odm()
      .child<IOrderData>('orders', filters)
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map(({ payload }) => {
            return Order.fromPayloadDocument(payload.doc);
          });
        })
      );

    return orders$;
  }
}
