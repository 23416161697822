import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/**
 * Shows elapsed time in `HH::mm::ss` format. When more than 1 day, it shows relative text (ex.: '2 days ago')
 */
@UntilDestroy()
@Component({
  selector: 'app-elapsed-time-counter',
  templateUrl: './elapsed-time-counter.component.html',
  styleUrls: ['./elapsed-time-counter.component.scss'],
})
export class ElapsedTimeCounterComponent implements OnInit, OnDestroy {
  @Input()
  public set since(value: moment.MomentInput | undefined | null) {
    if (!value) {
      this.initialValue = undefined;
    } else {
      this.initialValue = moment(value);
    }
  }

  @Input()
  public set until(value: moment.MomentInput | undefined | null) {
    // console.log('until', value);
    if (!value) {
      this.finalValue = undefined;
    } else {
      this.finalValue = moment(value);
    }

    // console.log('until', this.finalValue);
  }

  public initialValue: moment.Moment | undefined;
  public finalValue: moment.Moment | undefined;

  protected readonly valueSubject: BehaviorSubject<string | undefined>;

  /**
   * Obtiene un stream de los módulos habilitados para el Plan actual del restaraunt.
   *
   * Si el valor es undefined, significa que aún no se han inicializado (sucede cuando se cambia/inicializa el Plan)
   */
  public readonly value$: Observable<string | undefined>;

  /**
   * Si el valor es undefined, significa que aún no se han inicializado.
   */
  public get value(): string | undefined {
    return this.valueSubject.value;
  }

  constructor() {
    this.valueSubject = new BehaviorSubject<string | undefined>(undefined);
    this.value$ = this.valueSubject.asObservable().pipe(shareReplay(1));
  }

  ngOnInit() {
    const secondsIntervals$ = interval(1000);

    secondsIntervals$.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.initialValue) {
        if (this.value !== undefined) {
          this.valueSubject.next(undefined);
        }

        return;
      }

      const until = (this.finalValue ?? moment()).clone();

      const duration = moment.duration(until.diff(this.initialValue));

      if (duration.days() > 0) {
        // TODO: Aumentar el timer de 1 segundo para mejorar el desempeño
        this.valueSubject.next(this.initialValue.from(until, true));
      } else {
        const hh = duration.hours().toString().padStart(2, '0');
        const mm = duration.minutes().toString().padStart(2, '0');
        const ss = duration.seconds().toString().padStart(2, '0');

        // console.log('⏱', this.finalValue, duration);
        this.valueSubject.next(`${hh}:${mm}:${ss}`);
      }
    });
  }

  ngOnDestroy() {}
}
