import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

/**
 * Valores para el campo 'slug' en los módulos.
 */
export type FeatureSlug =
  | 'admin:custom-theme'
  | 'admin:customers-reservation'
  | 'admin:electronic-bills'
  | 'admin:external-billing-integration'
  | 'admin:hight-priority-support'
  | 'admin:reports'
  | 'admin:zone-bar'
  | 'admin:zone-kitchen'
  | 'admin:zone-tables'
  | 'attendant:native-application'
  | 'attendant:web-application'
  | 'customer:attendand-call'
  | 'customer:interactive-menu'
  | 'customer:native-application'
  | 'customer:payment-methods-integration'
  | 'customer:preview-menu'
  | 'customer:split-accounts'
  | 'customer:web-application'
  | 'regcustomer:preview-menu'
  | 'regcustomer:interactive-menu';

// Para obtener la lista actualizada manualmente:
// (new Module())
//   .odm()
//   .collection()
//   .get()
//   .subscribe((r) => {
//     console.log(r.docs.map((m) => m.data()).map((m) => m.slug));
//   });

export interface IFeatureData extends IFirebaseData {
  id: string | null;
  slug: FeatureSlug | string;
  title: string;
  description: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}

/**
 * Representa un módulo de funcionalidades.
 */
export class Feature extends Model<IFeatureData> {
  public static type = 'features';

  constructor(data: Partial<IFeatureData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IFeatureData = {
      id: null,
      slug: '',
      title: '',
      description: null,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  get slug() {
    return this.rawData.slug;
  }
}
