import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

import { TableSession } from './table-session';
import { ITableSessionUserData, ITableSessionUserRelations } from './table-session-user-data.interface';
import { User } from './user';

/**
 * Representa una mesa en la base de datos.
 */
export class TableSessionUser extends Model<ITableSessionUserData> implements ITableSessionUserRelations {
  public static type = 'users';

  private _tableSession: TableSession | undefined;

  get tableSession(): TableSession | undefined {
    return this._tableSession;
  }

  set tableSession(tableSession: TableSession | undefined) {
    this._tableSession = tableSession;
  }

  private _user: User | undefined;

  get user(): User | undefined {
    return this._user;
  }

  set user(user: User | undefined) {
    this._user = user;
  }

  constructor(data: Partial<ITableSessionUserData>, id: string | null = null, parentPath?: string) {
    const defaultData: ITableSessionUserData = {
      enabled: null,
      id: null,
      tableSessionId: '',
      userId: '',
      createdAt: null,
      updatedAt: null,
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  private _user$: Observable<User> | undefined;

  get user$() {
    if (!this._user$) {
      const user = new User({}, this.data.userId);

      this._user$ = user
        .odm()
        .doc()
        .get()
        .pipe(
          map((d) => {
            return new User(d.data(), d.id);
          }),
          tap((u) => {
            this.user = u;
          })
        );
    }

    return this._user$;
  }

  private _tableSession$: Observable<TableSession> | undefined;

  get tableSession$() {
    if (!this._tableSession$) {
      const restaurantPath = this.odm().parent()?.parent.parent?.path;

      if (!restaurantPath) {
        throw new Error('Unable to get parent path');
      }

      const session = new TableSession({}, this.data.tableSessionId, restaurantPath);

      this._tableSession$ = session
        .odm()
        .doc()
        .get()
        .pipe(
          map((d) => {
            return new TableSession(d.data() || {}, d.id);
          }),
          tap((s) => {
            this.tableSession = s;
          })
        );
    }

    return this._tableSession$;
  }
}
