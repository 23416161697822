import firebase from 'firebase/app';
import * as _ from 'lodash-es';
import { stringify } from 'querystring';
import { Observable } from 'rxjs';
import { map, tap,first, distinct,share } from 'rxjs/operators';
import Model from '~lib/database/model';
import { convertKeysToCamelCase, defaultedData } from '~lib/helpers';

import { IOrderProductData } from './order-product-data.interface';
import { Product } from './product';
import { IRestaurantData } from './restaurant-data.interface';
import { Order } from './order';
import {IOrderData } from './order-data.interface';
import { ILaravelApiSingleResult, LaravelApiService, UriRoute } from '~shared/services/laravel-api.service';
import { ServiceLocator } from '~lib/helpers/service-locator';
import { promise } from 'protractor';
/**
 * Representa los detalles de un pedido.
 */
export class OrderProduct extends Model<IOrderProductData> {
  public static type = 'order_products';
  currentUserOrder: any;
  public teamId : string | undefined;
  public localVar:Order | null | undefined;
  static apiService: LaravelApiService | undefined;

  public current_usr: IOrderData | undefined;

  constructor(data: Partial<IOrderProductData> = {}, id: string | null = null, parentPath?: string,) {
    const defaultData: IOrderProductData = {
      createdAt: null,
      id: null,
      orderId: '',
      price: 0,
      productId: '',
      meta: { status: 0, sortIndex: 0 },
      quantity: 0,
      notes: '',
      offerApplicable: false,
      promocode: '',
      updatedAt: null,
      promoquantity: 0,
      teamId:''
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);

    const params = {
      order: data.orderId ,
    };
    
    
  }

  
  get data() {
    return this.rawData;
  }
  
  private _product$: Observable<Product> | undefined;
 
  get product$(): Observable<Product> {
   if (!this._product$) {
    
    
      const parentPath = this.restaurantRef()?.path;
      console.log(parentPath)
      if(parentPath){
        this._product$ = new Product({}, this.data.productId, parentPath)
        .odm()
        .doc()
        .get()
        .pipe(
          map((snapshot) => {
            const data = snapshot.data();
            if (!data) {
              console.warn('Product do not exists in Firestore', new Product({}, this.data.productId, parentPath));
            }

            return new Product(data || {}, this.data.productId, parentPath);
          })
        );
      }else{
       
         const prodparent = `restaurants/${this.data.teamId}`;
          this._product$ = new Product({}, this.data.productId, prodparent)
          .odm()
          .doc()
          .get()
          .pipe(
            map((r) => {
              const data = r.data();
             
              if (!data) {
                console.warn('Product do not exists in Firestore25', new Product({}, this.data.productId, prodparent));
              }

              return new Product(data || {}, this.data.productId, prodparent);
            })
          );
       }
      
    }

    return this._product$;
  }
  
 
  private _order$: Observable<Order> | undefined;
  get order$() {
    if (!this._order$) {
     const order = new Order({}, this.data.orderId);
     
      this._order$ = order
        .odm()
        .doc()
        .get()
        .pipe(
          map((d) => {
             return new Order(d.data(), d.id);
          })
        );
    }
    return this._order$;
  }

  /**
   * Obtiene una referencia al documento del restaurant padre.
   */
  restaurantRef(): firebase.firestore.DocumentReference<IRestaurantData> | null {
    if (!this.parentPath) {
      throw new Error('Unable to get restaurant ref: parentPath is not defined');
    }

    return (this.odm().parent()?.parent.parent?.parent.parent ??
      null) as firebase.firestore.DocumentReference<IRestaurantData> | null;
  }
}
