import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import * as _ from 'lodash-es';
import { LoadingController, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IRole } from '~/database/models/role.interface';
import { User } from '~/database/models/user';
import IFirebaseData from '~lib/database/firebase-data.interface';
import { convertKeysToCamelCase } from '~lib/helpers';

import { ILaravelApiSingleResult, ILaravelApiTokenClaims, LaravelApiService, UriRoute } from './laravel-api.service';
import { SessionService } from './session.service';

// tslint:disable variable-name

export interface IAccessToken extends IFirebaseData {
  tokenId: number;
  tokenType: string;
  accessToken: string;
  firebaseToken: string;
}

/**
 * Servicio para manejar la autenticación de los usuarios.
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  // ==========================================================================
  protected readonly currentUserSubject: BehaviorSubject<firebase.User | null | undefined>;

  public get currentUser$(): Observable<firebase.User | null | undefined> {
    return this.currentUserSubject.asObservable();
  }

  public get currentUser(): firebase.User | null | undefined {
    return this.currentUserSubject.value;
  }
  // ==========================================================================

  // ==========================================================================
  protected readonly currentUserTypeSubject: BehaviorSubject<'customer' | 'regcustomer' |'attendant' | null | undefined>;

  public get currentUserType$(): Observable<'customer' |'regcustomer' | 'attendant' | null | undefined> {
    return this.currentUserTypeSubject.asObservable();
  }

  public get currentUserType(): 'customer' | 'regcustomer' |'attendant' | null | undefined {
    return this.currentUserTypeSubject.value;
  }

  // ==========================================================================

  protected readonly roleSubject: BehaviorSubject<IRole | null | undefined>;

  /**
   * Representa el rol actual del usuario para el restaurant de la sesión actual.
   */
  public readonly role$: Observable<IRole | null | undefined>;

  public get role() {
    return this.roleSubject.value;
  }

  // ==========================================================================
  protected readonly currentUserInstanceSubject: BehaviorSubject<User | null | undefined>;
  public readonly currentUserInstance$: Observable<User | null | undefined>;

  public get currentUserInstance() {
    return this.currentUserInstanceSubject.value;
  }

  constructor(private api: LaravelApiService, private afa: AngularFireAuth, private currentSession: SessionService,public toastController: ToastController,private loadingCtrl: LoadingController,) {
    this.currentUserSubject = new BehaviorSubject<firebase.User | null | undefined>(undefined);
    this.currentUserInstanceSubject = new BehaviorSubject<User | null | undefined>(undefined);
    this.currentUserTypeSubject = new BehaviorSubject<'customer' | 'regcustomer' | 'attendant' | null | undefined>(undefined);
    this.roleSubject = new BehaviorSubject<IRole | null | undefined>(undefined);

    this.role$ = this.roleSubject.asObservable().pipe(shareReplay(1));
    this.currentUserInstance$ = this.currentUserInstanceSubject.asObservable().pipe(shareReplay(1));

    afa.onAuthStateChanged((user) => {
      // console.log('onAuthStateChanged');
      this.currentUserSubject.next(user);

      if (user) {
        const uInstance = new User({ name: user.displayName ?? '' }, user.uid);
        
        uInstance
          .odm()
          .doc()
          .get()
          .pipe(first())
          .subscribe((u) => {
            const userData = u.data();

            // console.log('💀', userData);

            if (userData) {
              uInstance.fill(userData);
            }
          });

        this.currentUserInstanceSubject.next(uInstance);
        
      } else if (user === null) {
        this.currentUserInstanceSubject.next(null);
      }
    });

    afa.onIdTokenChanged(async (user) => {
      // console.log('onIdTokenChanged', user);
      await this.syncIdTokenResult(user);
      // console.log('onIdTokenChanged end');
      //
    });
  }

  async getIdTokenResult(
    user: firebase.User | null
  ): Promise<(firebase.auth.IdTokenResult & { claims: ILaravelApiTokenClaims }) | null> {
    if (!user) {
      return null;
    }

    const tokenResult = (await user.getIdTokenResult()) as firebase.auth.IdTokenResult & {
      claims: ILaravelApiTokenClaims;
    };

    // console.log({ tokenResult });

    return tokenResult;
  }

  /**
   * Sincroniza la metadata del usuario a los respectivos servicios.
   *
   * - Sincroniza el token de autenticación para las llamadas a la API de Laravel.
   * - Obtiene el tipo de usuario.
   */
  async syncIdTokenResult(user: firebase.User | null) {
    const results = await this.getIdTokenResult(user);

    if (!results) {
      this.api.accessToken = null;

      this.currentUserTypeSubject.next(null);

      this.currentSession.clear();

      return;
    }

    // console.log('syncIdTokenResult', results);

    this.api.accessToken = results.claims.access_token;
    
    this.currentUserTypeSubject.next(results.claims.is_attendant ? 'attendant' : 'customer');
  
    if(!results.claims.is_attendant){
      this.currentUserTypeSubject.next(results.claims.reg_type == 'regcustomer' && results.claims.reg_type  !=  null ? 'regcustomer' : 'customer');
    }

    //this.currentUserTypeSubject.next(results.claims.is_attendant ? 'attendant')


    const { restaurantId, tableSessionId } = convertKeysToCamelCase(results.claims) as {
      restaurantId: string | null;
      tableSessionId: string | null;
    };
    
     if (tableSessionId) {
        await this.currentSession.init({ tableSessionId });
      } else if (restaurantId) {
        await this.currentSession.init({ restaurantId });
      } else {
        this.currentSession.clear();
      }
   
    

    if (this.currentSession.restaurant?.id === undefined || this.currentUser === undefined) {
      this.roleSubject.next(undefined);
    } else if (this.currentSession.restaurant.id === null || this.currentUser === null) {
      this.roleSubject.next(null);
    } else {
     
      const result = (await this.api
        .get<IRole>(
          new UriRoute('users/{user}/teams/{team}/role', {
            user: this.currentUser.uid,
            team: this.currentSession.restaurant.id,
          })
        )
        .pipe(first())
        .toPromise()) as ILaravelApiSingleResult<IRole>;
       
      

      this.roleSubject.next(result.data);
    }
  }

  /**
   * Reautentica al usuario para un restaurant distinto.
   *
   * Se puede usar el restaurant directamente o una mesa del restaurant.
   */

   
   async reauthenticateWithRestaurantInternally(info: { tableId: string | null}) {
   

    console.log(info)
    if (!this.currentUser) {
      return;
    }
   
    // TODO: Comprobar que no tenga nada pendiente.
    // TODO: Comprobar que no sean el mismo

    const token = this.api.getAccessToken()?.id;

    if (!token) {
      console.warn('Unable to get the token id of ', this.api.getAccessToken());
      return;
    }

    const requestBody = _.omitBy({tableId:info?.tableId,login_type:'tableLogin'}, _.isNil);
  //  const requestBody = _.omitBy({tableId:info?.tableId,login_type:'tableLogin'}, _.isNil);
 
    const result = await this.api
      .patch<IAccessToken>(new UriRoute('auth/tokens/{token}', { token }), requestBody)
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    // console.log('updateClaims', info, result);

    if (result) {
     //  this.currentSession.clear();
      await this.signInWithToken(result.firebaseToken);
    }

   }
  async reauthenticateWithRestaurant(info: { restaurantId: string } | { tableId: string  }) {
    console.log(info)
    if (!this.currentUser) {
      return;
    }
   
    // TODO: Comprobar que no tenga nada pendiente.
    // TODO: Comprobar que no sean el mismo

    const token = this.api.getAccessToken()?.id;

    if (!token) {
      console.warn('Unable to get the token id of ', this.api.getAccessToken());
      return;
    }

    const requestBody = _.omitBy(info, _.isNil);
 
    const loading = await this.loadingCtrl.create({ backdropDismiss: false, message: 'Fetching Commerce Data...'});
    await loading.present()
    const result = await this.api
      .patch<IAccessToken>(new UriRoute('auth/tokens/{token}', { token }), requestBody)
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();
     
    // console.log('updateClaims', info, result);

    if (result) {
     //  this.currentSession.clear();
      await this.signInWithToken(result.firebaseToken);
      await loading.dismiss()
    }
  }

  async reauthenticateWithTable(info: { tableSessionId: string | null,tableId: string}) {
    
    console.log(info.tableSessionId)
    if (!this.currentUser) {
      return;
    }
   
    // TODO: Comprobar que no tenga nada pendiente.
    // TODO: Comprobar que no sean el mismo

    const token = this.api.getAccessToken()?.id;

    if (!token) {
      console.warn('Unable to get the token id of ', this.api.getAccessToken());
      return;
    }

    const requestBody = _.omitBy({tableSessionId:info?.tableSessionId,tableId:info?.tableId,login_type:'tableLogin'}, _.isNil);
   
    const result = await this.api
      .patch<IAccessToken>(new UriRoute('auth/tokens/{token}', { token }), requestBody)
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    // console.log('updateClaims', info, result);

    if (result) {
     // this.currentSession.clear();
      await this.signInWithToken(result.firebaseToken);
    }
  }

  /**
   * @deprecated Use CurrentRestaurantService
   */
  async setCurrentRestaurant(restaurantId: string) {
    // TODO: Reautenticar al usuario usando el restaurant especificado
    await this.currentSession.init(restaurantId);
  }

  /**
   * @deprecated Use CurrentRestaurantService
   */
  async clearCurrentRestaurant() {
    // TODO: Reautenticar al usuario quitando el restaurant
    this.currentSession.clear();
  }

  async signOut() {
    if (!this.currentUser) {
      return;
    }

    await this.afa.signOut();
    this.currentSession.clear();
   
  }

  async signInAnonymously(userInfo: { name: string }) {
    if (this.currentUser) {
      return;
    }

    const restaurant_id = this.currentSession.restaurant?.id;
    const table_id = this.currentSession.table?.id;

    const bodyData = _.omitBy({ ...userInfo, restaurant_id, table_id, token_name: environment.appId ,reg_type:'customer'}, _.isUndefined);

    const result = await this.api
      .post<IAccessToken>(new UriRoute('auth/tokens'), bodyData, { params: { anonymous: 'true' } })
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    if (result) {
      // const cred = BeFasterAuthProvider.credential(result.firebaseToken, credentials);
      const userCredential = await this.signInWithToken(result.firebaseToken);

      // await this.syncIdTokenResult();
      // console.log({ cred });
      // const userCredential = await this.afa.signInWithCredential(cred);

      console.log('signInAnonymously', { userCredential });
    }
  }

  async signInWithEmail(credentials: { email: string; password: string,userId:string }) {
    if (this.currentUser) {
      return;
    }

    const restaurant_id = this.currentSession.restaurant?.id;
    const table_id = this.currentSession.table?.id;

    const bodyData = _.omitBy(
      { ...credentials, restaurant_id, table_id, token_name: environment.appId },
      _.isUndefined
    );

    const result = await this.api
      .post<IAccessToken>(new UriRoute('auth/tokens'), bodyData, { params: { anonymous: 'false' } })
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    if (result) {
  
      // const cred = BeFasterAuthProvider.credential(result.firebaseToken, credentials);
      const userCredential = await this.signInWithToken(result.firebaseToken);

      // await this.syncIdTokenResult();
      // console.log({ cred });
      // const userCredential = await this.afa.signInWithCredential(cred);

     
    }
  }
  async resetPassword(credentials: { password: string;'confirm-password':string; }) {
    if (this.currentUser) {
      return;
    }

    const restaurant_id = this.currentSession.restaurant?.id;
    const table_id = this.currentSession.table?.id;

    const bodyData = _.omitBy(
      { ...credentials, token_name: environment.appId },
      _.isUndefined
    );

    const result = await this.api
      .post<IAccessToken>(new UriRoute('auth/tokens/reset-password'), bodyData, { params: { anonymous: 'false' } })
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    if (result) {
  
      // const cred = BeFasterAuthProvider.credential(result.firebaseToken, credentials);
      //const userCredential = await this.signInWithToken(result.firebaseToken);

      // await this.syncIdTokenResult();
      // console.log({ cred });
      // const userCredential = await this.afa.signInWithCredential(cred);

     
    }
  }
  async confirmEmail(credentials: { email: string; }) {
    if (this.currentUser) {
      return;
    }

    const restaurant_id = this.currentSession.restaurant?.id;
    const table_id = this.currentSession.table?.id;

    const bodyData = _.omitBy(
      { ...credentials, token_name: environment.appId },
      _.isUndefined
    );

    const result = await this.api
      .post<IAccessToken>(new UriRoute('auth/tokens/forgot-password'), bodyData, { params: { anonymous: 'false' } })
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    if (result) {
  
      // const cred = BeFasterAuthProvider.credential(result.firebaseToken, credentials);
      //const userCredential = await this.signInWithToken(result.firebaseToken);

      // await this.syncIdTokenResult();
      // console.log({ cred });
      // const userCredential = await this.afa.signInWithCredential(cred);

     
    }
  }
  async verifyWithOTP(credentials: { verify_code: string; }) {
    if (this.currentUser) {
      return;
    }

    const restaurant_id = this.currentSession.restaurant?.id;
    const table_id = this.currentSession.table?.id;

    const bodyData = _.omitBy(
      { ...credentials, restaurant_id, table_id, token_name: environment.appId },
      _.isUndefined
    );

    const result = await this.api
      .post<IAccessToken>(new UriRoute('auth/tokens/verify-otp'), bodyData, { params: { anonymous: 'false' } })
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    if (result) {
      const userCredential = await this.signInWithToken(result.firebaseToken);
      // const cred = BeFasterAuthProvider.credential(result.firebaseToken, credentials);
      //const userCredential = await this.signInWithToken(result.firebaseToken);

      // await this.syncIdTokenResult();
      // console.log({ cred });
      // const userCredential = await this.afa.signInWithCredential(cred);

     
    }
  }
  async verifyAccount(credentials: { country_code: string; phone_number: string }) {
    if (this.currentUser) {
      return;
    }

    const restaurant_id = this.currentSession.restaurant?.id;
    const table_id = this.currentSession.table?.id;

    const bodyData = _.omitBy(
      { ...credentials, restaurant_id, table_id, token_name: environment.appId },
      _.isUndefined
    );

    const result = await this.api
      .post<IAccessToken>(new UriRoute('auth/tokens/verify'), bodyData, { params: { anonymous: 'false' } })
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    if (result) {
      
      /*this.zone.run(async () => {
        await this.navCtrl.navigateForward('/verify-otp');
      });*/
      // const cred = BeFasterAuthProvider.credential(result.firebaseToken, credentials);
     // const userCredential = await this.signInWithToken(result.firebaseToken);

      // await this.syncIdTokenResult();
      // console.log({ cred });
      // const userCredential = await this.afa.signInWithCredential(cred);

     
    }
  }


  async registerUser(credentials: { name:string,email: string;country_code:string;phone_number:string; password: string;confirmpassword:string }) {
    
    if (this.currentUser) {
      return;
    }

    const restaurant_id = this.currentSession.restaurant?.id;
    const table_id = this.currentSession.table?.id;

    const bodyData = _.omitBy(
      { ...credentials, restaurant_id, table_id, token_name: environment.appId,reg_type:'regcustomer' },
      _.isUndefined
    );

    const result = await this.api
      .post<IAccessToken>(new UriRoute('auth/tokens/register'), bodyData, { params: { anonymous: 'false' } })
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    if (result) {
      this.toastController
      .create({
        message: 'Your account is created successfuly.',
        duration: 3000,
        position: 'top',
      })
      .then((toast) => {
        toast.present();
      });
     // console.log(result.firebaseToken);
      // const cred = BeFasterAuthProvider.credential(result.firebaseToken, credentials);
      //const userCredential = await this.signInWithToken(result.firebaseToken);

      // await this.syncIdTokenResult();
      // console.log({ cred });
      // const userCredential = await this.afa.signInWithCredential(cred);

      //console.log({ userCredential });
    }
  }
  
  async signInWithGoogleEmail(credentials: { email: string; social_id: string,usertype:string }) {
    if (this.currentUser) {
      return;
    }

    const restaurant_id = this.currentSession.restaurant?.id;
    const table_id = this.currentSession.table?.id;

    const bodyData = _.omitBy(
      { ...credentials, restaurant_id, table_id, token_name: environment.appId },
      _.isUndefined
    );

    const result = await this.api
      .post<IAccessToken>(new UriRoute('auth/tokens/social'), bodyData, { params: { anonymous: 'false' } })
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    if (result) {
      console.log(result.firebaseToken);
      const userCredential = await this.signInWithToken(result.firebaseToken);
    }
  }
  async signUpWithGoogleEmail(credentials: {name:string; email: string; social_id: string,reg_type:'customer' }) {
    if (this.currentUser) {
      return;
    }

    const restaurant_id = this.currentSession.restaurant?.id;
    const table_id = this.currentSession.table?.id;

    const bodyData = _.omitBy(
      { ...credentials, restaurant_id, table_id, token_name: environment.appId },
      _.isUndefined
    );

    const result = await this.api
      .post<IAccessToken>(new UriRoute('auth/tokens/social_customer'), bodyData, { params: { anonymous: 'false' } })
      .pipe(
        first(),
        map((res) => res.data)
      )
      .toPromise();

    if (result) {
      console.log(result.firebaseToken);
      // const cred = BeFasterAuthProvider.credential(result.firebaseToken, credentials);
      const userCredential = await this.signInWithToken(result.firebaseToken);

      // await this.syncIdTokenResult();
      // console.log({ cred });
      // const userCredential = await this.afa.signInWithCredential(cred);

      console.log({ userCredential });
    }
  }

  /**
   * Inicia sesión con el token obtenido del backend de Laravel.
   */
  protected async signInWithToken(firebaseToken: string) {
    return await this.afa.signInWithCustomToken(firebaseToken);
  }
}
