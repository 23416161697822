import * as moment from 'moment';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

import { INotificationData } from './notification-data.interface';

export class NotificationDocument<T extends IFirebaseData = IFirebaseData> extends Model<INotificationData<T>> {
  public static type = 'notifications';

  constructor(data: Partial<INotificationData<T>>, id: string | null = null, parentPath?: string) {
    const defaultData: INotificationData<T> = {
      context: null,
      group: null,
      body: '',
      icon: '',
      title: '',
      data: {} as T,
      createdAt: null,
      readAt: null,
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id, parentPath);
  }

  get data() {
    return this.rawData;
  }

  async markAsRead() {
    await this.odm().doc().update({ readAt: moment().toISOString() });
  }

  public get readAt() {
    if (typeof this.rawData.readAt !== 'string') {
      return null;
    }

    return moment(this.rawData.readAt);
  }
}
