import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { interval, Subscription } from 'rxjs';
import { Product } from '~/database/models/product';
import { normalizeBooleanAttribute } from '~lib/helpers';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
})
export class ProductItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  product: Product | undefined;

  private _button = false;
  private _disabled = false;

  @Input()
  set disabled(value: boolean | string | undefined) {
    this._disabled = normalizeBooleanAttribute(value);
  }
  get disabled() {
    return this._disabled;
  }

  @Input()
  set button(value: boolean | string | undefined) {
    this._button = normalizeBooleanAttribute(value);
  }

  get button() {
    return this._button;
  }

  @Input()
  detail = false;

  @ViewChild('img') imgEl: ElementRef<HTMLImageElement> | undefined;
  protected updatingImageSizes = false;
  protected updateImageSizesSubscription: Subscription | undefined;
  sizes = '1px';

  constructor(public platform: Platform) {}

  ngOnInit() {}

  async ngAfterViewInit() {
    const source = interval(2000);
    this.updateImageSizes();
    this.updateImageSizesSubscription = source.subscribe(() => {
      this.updateImageSizes();
    });
  }

  updateImageSizes() {
    if (this.updatingImageSizes) {
      return;
    }

    this.updatingImageSizes = true;
    const innerWidth: number = this.platform.width();

    const nativeEl = this.imgEl?.nativeElement;

    if (!nativeEl) {
      this.updatingImageSizes = false;
      return;
    }

    window.requestAnimationFrame(() => {
      const currentWidth = nativeEl.getBoundingClientRect().width;

      // console.log(innerWidth, currentWidth);
      if (currentWidth > 0) {
        this.sizes = Math.ceil((currentWidth / innerWidth) * 100) + 'vw';
        this.updateImageSizesSubscription?.unsubscribe();
      }

      this.updatingImageSizes = false;
    });
  }

  ngOnDestroy() {
    if (this.updateImageSizesSubscription && !this.updateImageSizesSubscription.closed) {
      this.updateImageSizesSubscription.unsubscribe();
    }
  }
}
