
import * as _ from 'lodash-es';
import Model from '~lib/database/model';
import {  defaultedData } from '~lib/helpers';
import { IOrderPaymentData } from './order-payment-data.interface';
import { Order } from './order';
import {IOrderData } from './order-data.interface';
import { LaravelApiService } from '~shared/services/laravel-api.service';

/**
 * Representa los detalles de un pedido.
 */
export class OrderPayment extends Model<IOrderPaymentData> {
  public static type = 'order_payments';

  constructor(data: Partial<IOrderPaymentData> = {}, id: string | null = null, parentPath?: string,) {
    const defaultData: IOrderPaymentData = {
      createdAt: null,
      id: null,
      orderId: '',
      userId: '',
      driverPaymentStatus:'',
      teamPaymentStatus:'',
      attendantPaymentStatus:'',
      updatedAt: null,
     
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);

  }

  get data() {
    return this.rawData;
  }

}
