import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Color } from '@ionic/core';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
export type OrderStatusSlug =
  | 'rejected'
  | 'waiting'
  | 'confirmed'
  | 'preparing'
  | 'delivering'
  | 'delivered'
  | 'billing'
  | 'billed'
  | 'make-payment'
  | 'order-rejected'
  | 'order-waiting'
  | 'order-confirmed'
  | 'order-preparing'
  | 'order-delivering'
  | 'order-delivered'
  | 'order-billing'
  | 'order-billed'
  | 'ready-for-payment'
  | 'awaiting-driver-confirmation'
  | 'assign-new-driver';

export interface IOrderStatusData extends IFirebaseData {
  id: string | null;
  slug: OrderStatusSlug;
  title: string;
  description: string | null;
  value: number;

  createdAt: string | null;
  updatedAt: string | null;
}

/**
 * Representa los detalles de un pedido.
 */
export class OrderStatus extends Model<IOrderStatusData> {
  public static type = 'order_statuses';

  constructor(data: Partial<IOrderStatusData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IOrderStatusData = {
      id: null,
      slug: 'waiting',
      title: '',
      description: null,
      value: 0,

      createdAt: null,
      updatedAt: null,
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  public get value(): number {
    return this.data.value;
  }

  public get slug(): OrderStatusSlug {
    return this.data.slug;
  }

  public get title() {
    return this.data.title;
  }
  public set title(value) {
    this.data.title = value;
  }

  public get ionColor(): Color | undefined {
    let color: Color | undefined;

    switch (this.slug) {
      case 'billed':
      case 'billing':
      case 'make-payment':
      case 'delivered':
        color = 'success';
        break;
      case 'order-billed':
      case 'order-billing':
      case 'order-delivered':
        color = 'success';
        break;
      case 'delivering':
      case 'preparing':
        color = 'warning';
        break;

      case 'confirmed':
        color = 'primary';
        break;
      case 'order-confirmed':
        color = 'primary';
        break;
      case 'waiting':
        color = 'deep';
        break;
      case 'order-waiting':
        color = 'deep';
        break;
      case 'rejected':
        color = 'danger';
      case 'order-rejected':
        color = 'danger';
      case 'order-delivering':
      case 'order-preparing':
        color = 'warning';
        break;
    }

    return color;
  }

  public get faIcon(): IconProp | undefined {
    let icon: IconProp | undefined;

    switch (this.slug) {
      case 'billed':
        icon = ['fas', 'clipboard-check'];
        break;

      case 'billing':
        icon = ['far', 'clipboard'];
        break;

      case 'delivered':
        icon = ['fas', 'check-circle'];
        break;

      case 'delivering':
        icon = 'truck-moving';
        break;

      case 'preparing':
        icon = 'tasks';
        break;

      case 'confirmed':
        icon = ['far', 'calendar-check'];
        break;

      case 'waiting':
        icon = 'pause-circle';
        break;

      case 'rejected':
        icon = 'times-circle';

        case 'order-billed':
        icon = ['fas', 'clipboard-check'];
        break;
        case 'make-payment':
          icon = ['fas', 'clipboard-check'];
          break;
      case 'order-billing':
        icon = ['far', 'clipboard'];
        break;

      case 'order-delivered':
        icon = ['fas', 'check-circle'];
        break;

      case 'order-delivering':
        icon = 'truck-moving';
        break;

      case 'order-preparing':
        icon = 'tasks';
        break;

      case 'order-confirmed':
        icon = ['far', 'calendar-check'];
        break;

      case 'order-waiting':
        icon = 'pause-circle';
        break;

       case 'ready-for-payment': 
       icon = 'pause-circle';
       break;
       
      case 'order-rejected':
        icon = 'times-circle'; 

      case 'awaiting-driver-confirmation':
          icon = 'times-circle'; 

      case 'assign-new-driver':
         icon = 'times-circle'; 
    }

    return icon;
  }
}
