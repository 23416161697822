import { Component, Input, OnInit } from '@angular/core';
import { Category } from '~/database/models/category';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss'],
})
export class CategoryCardComponent implements OnInit {
  promotion: any;
  weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  //offercodeApplicable = false;

  @Input()
  item: Category | undefined;

  @Input()
  badgeColor: string | undefined = 'dark';

  @Input()
  deliveryFee: number | undefined = 0;

  constructor() {}
  add_zero(num: number): string {
    if (num < 9) return '0o' + num;
    else return '' + num;
  }
  compareTime(str2: string, str1: string) {
    if (str1 === str2) {
      return 0;
    }
    var time1 = str1.split(':');
    var time2 = str2.split(':');
    if (eval(time1[0]) > eval(time2[0])) {
      return 1;
    } else if (eval(time1[0]) == eval(time2[0]) && eval(time1[1]) >= eval(time2[1])) {
      return 1;
    } else {
      return -1;
    }
  }
  checkforlabel() {
     this.promotion = this.item;
    const offer = this.promotion.rawData.offer;
    this.promotion.rawData.offercodeApplicable = false;
    if (offer) {
      const array = JSON.parse(offer);
      const d = new Date();
      const currentDay = this.weekday[d.getDay()];
      const currentTime = d.getHours() + ':' + d.getMinutes();

      array.forEach((value: any) => {
        const start_time = value.Start.hours + ':' + value.Start.minutes;
        const end_time = value.End.hours + ':' + value.End.minutes;

        if (
          currentDay == value.Day &&
          this.compareTime(start_time, currentTime) >= 0 &&
          this.compareTime(currentTime, end_time) >= 0
        ) {
          this.promotion.rawData.offercodeApplicable = true;
        }
      });
      this.item = this.promotion;
    }
  }
  ngOnInit() {
    setInterval(() => {
      this.checkforlabel();
    }, 10000);
  }
}
