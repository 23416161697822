import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { firebaseConfig } from 'src/environments/environment';
import '~lib/extensions/string';
import { ServiceLocator } from '~lib/helpers/service-locator';
import { SharedComponentsModule } from '~shared/components/shared-components.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {File} from '@ionic-native/file/ngx';
import { GoogleLoginProvider } from 'angularx-social-login';
import {Base64} from "@ionic-native/base64/ngx";
import { AgmCoreModule } from '@agm/core';
import { env } from '~lib/helpers/env';
import { NgxCaptureModule } from 'ngx-capture';
/**
 * Loader para las traducciones.
 */
export function translateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,
    SharedComponentsModule,
    HttpClientModule,
    NgxCaptureModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAQsut_-44f0WP1oCzv2kaUd1Fnk7RWTz4',
      libraries: ['places']
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),

    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    SocialLoginModule,
  ],
  providers: [
    GooglePlus,
    FileChooser,File,Base64,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('2816915519-9fdgql7crnv2imasalqjgg6ubng8q3kk.apps.googleusercontent.com', {
              scope: 'email',
              plugin_name: 'befaster-google-login'
            }),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  
  constructor(library: FaIconLibrary, private injector: Injector) {
    library.addIconPacks(fas, far, fab);
    ServiceLocator.injector = injector;
  }

}
 
