import { DocumentSnapshot, QueryDocumentSnapshot } from '@angular/fire/firestore';
import * as _ from 'lodash-es';
import * as moment from 'moment';

import IFirebaseData from './firebase-data.interface';
import { CloudFirestoreOdm } from './firestore/cloud-firestore-odm';
import { ISerializable } from './serializable.interface';

export type ModelConstructor<T, U> = new (rawData: T, id: string | null, parentPath?: string) => U;

/**
 * Modelo de datos para trabajar con documentos de Firebase.
 *
 * Herederos: Deben implementar un constructor.
 *
 * @author Nelson Martell <nelson6e65@gmail.com>
 */
export default abstract class Model<T extends IFirebaseData> implements ISerializable {
  /**
   * Representa el tipo del modelo; el nombre de la colección/tabla en la que se guardan.
   *
   * Los herederos deben establecer esta propiedad.
   * Ejemplo: 'users'
   */
  public static type: string | undefined;

  /**
   * Almacén en crudo de la data de firestore.
   */
  protected readonly rawData: T;

  /**
   * Obtiene el ID del modelo.
   */
  public readonly id: string | null;

  protected odmInstance: CloudFirestoreOdm<T> | undefined;

  /**
   *
   *
   * @param rawData    Documento en crudo de Firebase.
   * @param id         ID del documento.
   * @param parentPath Opcional. Si el documento pertenece a una subcolección, indica la ruta del documento padre.
   */
  constructor(rawData: T, id: string | null = null, public readonly parentPath?: string) {
    this.id = id;
    this.rawData = rawData;
  }

  /**
   * Crea la instancia del modelo a partir de un payload.
   *
   * Se generan a partir de subscribirse a un snapshotChanges de una colección de Firestore
   */
  static fromPayloadDocument<U extends Model<T>, T extends IFirebaseData>(
    this: ModelConstructor<T, U>,
    payloadDoc: QueryDocumentSnapshot<T>
  ) {
    const parentPath = payloadDoc.ref.parent.parent?.path;

    return new this(payloadDoc.data(), payloadDoc.id, parentPath);
  }

  /**
   * Crea la instancia del modelo a partir de un payload que se genera al subscribirse a un snapshotChanges de un documento de Firestore.
   */
  static fromDocumentChange<U extends Model<T>, T extends IFirebaseData>(
    this: ModelConstructor<T, U>,
    payload: DocumentSnapshot<T>
  ) {
    if (!payload.exists) {
      return null;
    }

    const parentPath = payload.ref.parent.parent?.path;

    const data = payload.data();

    return new this(data, payload.id, parentPath);
  }

  /**
   * Obtiene el ODM interno para trabajar con el documento, o su colección padre, en Firebase.
   */
  public odm(): CloudFirestoreOdm<T> {
    if (!this.odmInstance) {
      const collection = (this.constructor as typeof Model).type;

      if (!collection) {
        throw new Error('Model has not defined `type`');
      }

      this.odmInstance = new CloudFirestoreOdm<T>(
        {
          name: collection,
          parent: this.parentPath,
        },
        this.id
      );
    }

    return this.odmInstance;
  }

  /**
   * Muestra un texto que describa a la instancia.
   *
   * Este método debería ser reemplazado en herederos.
   */
  public displayName(): string {
    return this.toString();
  }

  public toString() {
    const name = (this.constructor as typeof Model).type || this.constructor.name;

    return `${name} { id: ${this.id} }`;
  }

  public serialize(): T {
    return this.rawData;
  }

  /**
   * Indica si el elemento está soft-deleted.
   */
  public isDeleted(): boolean {
    return !_.isNil(this.rawData.deletedAt);
  }

  public get createdAt() {
    if (typeof this.rawData.createdAt !== 'string') {
      return null;
    }

    return moment(this.rawData.createdAt);
  }

  public get updatedAt() {
    if (typeof this.rawData.updatedAt !== 'string') {
      return null;
    }

    return moment(this.rawData.updatedAt);
  }

  /**
   * Compara la data de esta instancia con la de otra para determinar si tiene los mismos valores de sus atributos.
   */
  public equalTo<TData extends IFirebaseData, TModel extends Model<TData>>(other: TModel | null | undefined): boolean {
    if (!other) {
      return false;
    }

    return _.isEqual(this.rawData, other.rawData);
  }

  public get data(): T {
    return this.rawData;
  }

  /**
   * Permite volver a llenar parcialmente la data, actualizándola en conjunto.
   */
  public fill(data: Partial<T>) {
    Object.keys(this.rawData).forEach((prop: keyof T) => {
      const value = data[prop] ?? undefined;

      if (value !== undefined) {
        this.rawData[prop] = value;
      }
    });

    return this;
  }
}
