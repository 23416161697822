import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { normalizeBooleanAttribute } from '~lib/helpers';

export interface MenuItemClickedEventDetail {
  url?: string;
}

export interface MenuItemInputs {
  faIcon?: IconProp;
  url?: string;
  label?: string;
  color?: string;
  disabled: boolean;
  skipToggle?: boolean;
}

// tslint:disable variable-name
@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
  public readonly inputs: MenuItemInputs = {
    disabled: false,
  };

  @Input()
  set disabled(value: boolean | string | undefined) {
    this.inputs.disabled = normalizeBooleanAttribute(value);
  }

  /**
   * Permite evitar el auto-toggle del menú al activar el ítem.
   */
  @Input()
  set skipToggle(value: boolean | string | undefined) {
    this.inputs.skipToggle = normalizeBooleanAttribute(value);
  }

  @Input()
  set color(value: string | undefined) {
    this.inputs.color = value || undefined;
  }

  @Input()
  set url(value: string | undefined) {
    this.inputs.url = value || undefined;
  }

  @Input()
  set label(value: string | undefined) {
    this.inputs.label = value || '';
  }

  @Input()
  set faIcon(value: IconProp | undefined) {
    if (!value) {
      this.inputs.faIcon = undefined;

      return;
    }

    if (typeof value === 'string') {
      value = ['fas', value];
    } else if (Array.isArray(value)) {
      if (value.length < 2) {
        value = ['fas', value[0] as IconName];
      } else {
        value = [value[0], value[1]];
      }
    }

    this.inputs.faIcon = value;
  }

  get button() {
    return this.url || this.clicked.observers.length > 0;
  }

  @Output()
  public readonly clicked = new EventEmitter<MenuItemClickedEventDetail>();

  constructor() {}

  ngOnInit() {
    //
  }

  onItemClick() {
    if (!this.inputs.disabled) {
      this.clicked.emit({ url: this.url });
    }
  }
}
