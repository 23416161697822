import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, switchMap, timeout } from 'rxjs/operators';
import { SessionService } from '~shared/services/session.service';

/**
 * Los módulos principales ('/', '/attendant', '/customer') no se deben proteger con este Guard.
 */
@Injectable({ providedIn: 'root' })
export class RestaurantSelectedGuard implements CanActivate {
  constructor(protected restaurantService: SessionService, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.restaurantService.restaurant) {
      return true;
    } else if (this.restaurantService.restaurant === null) {
      return this.getRedirectionUrl(state);
    }

    const result = this.restaurantService.restaurant$.pipe(
      filter((r) => r !== undefined),
      timeout(5000),
      first(),
      switchMap((r) => {
        // console.log(r);
        if (!r) {
          return of(this.getRedirectionUrl(state));
        }

        return of(true);
      }),
      catchError((err) => {
        // FIXME: Autenticar primero
        console.error('Error al capturar el restaurant actual', err);
        return of(this.getRedirectionUrl(state));
      })
    );
      console.log(result)
    return result;  
  }

  protected getRedirectionUrl(state: RouterStateSnapshot): UrlTree {
    const intendedUrlBase = state.url.replace(/^(\/|\s)+|(\s|\/)+$/g, '').split('/')[0] ?? '';
    console.log(intendedUrlBase)
    let redirectUrl = '/';

    switch (intendedUrlBase) {
      case 'attendant':
        redirectUrl = '/attendant/select-restaurant';
        break;
      
      case 'driver':
          redirectUrl = '/driver/delivery-orders';
          break;

      case 'regcustomer':
        redirectUrl = '/regcustomer/home';
        break;

      case 'customer':
       redirectUrl = `/customer/home`;
       break;

      default:
        redirectUrl = '/scan-qr';
    }
    console.log(redirectUrl)
    return this.router.parseUrl(redirectUrl);
  }
}
