import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { normalizeBooleanAttribute } from '~lib/helpers';

@Component({
  selector: 'customer-app-section-card',
  templateUrl: './customer-section-card.component.html',
  styleUrls: ['./customer-section-card.component.scss'],
})
export class CustomerSectionCardComponent implements OnInit {
  #disabled = false;
  #button: boolean | undefined;

  @Input()
  color: string | undefined;

  @Input()
  contentColor: string | undefined;

  @Input()
  innerClass: string | undefined;

  @Input()
  faIcon: IconProp | undefined;

  @Input()
  title: string | undefined;

  @Input()
  deliveryFee: string | undefined;

  @Input()
  distance: string | undefined;

  @Input()
  configuration: string | undefined;

  @Input()
  subtitle: string | undefined;

  @Input()
  set disabled(value: boolean | string | undefined) {
    this.#disabled = normalizeBooleanAttribute(value);
  }
  get disabled() {
    return this.#disabled;
  }

  @Input()
  set button(value: string | boolean | undefined) {
    if (value === undefined) {
      this.#button = undefined;
      return;
    }

    this.#button = normalizeBooleanAttribute(value);
  }
  get button() {
    if (this.#button === undefined) {
      return this.clicked.observers.length > 0;
    }

    return this.#button;
  }

  /**
   * Obtiene el ID de la mesa cuando se le da clic al card.
   */
  @Output()
  public readonly clicked: EventEmitter<MouseEvent>;

  constructor() {
    this.clicked = new EventEmitter<MouseEvent>();
  }

  ngOnInit() {
   
    
    //
  }

  triggerClicked(ev: MouseEvent | CustomEvent | Event) {
    this.clicked.emit(ev as MouseEvent);
  }
}
