import Model from '~lib/database/model';

import { Feature, IFeatureData } from './feature';
import { FeaturePlan, IFeaturePlanData } from './feature-plan';

export interface IFeatureWithPivotData extends IFeatureData {
  pivot: IFeaturePlanData;
}

/**
 * Representa a un módulo con pivote para los planes.
 */
export class FeatureWithPivot extends Model<IFeatureWithPivotData> {
  public static type = 'features';

  constructor(data: Partial<IFeatureWithPivotData> = {}, id: string | null = null, parentPath?: string) {
    const safeData: IFeatureWithPivotData = {
      ...new Feature(data).data,
      pivot: new FeaturePlan(data.pivot ?? {}).data,
    };

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  get slug() {
    return this.rawData.slug;
  }
}
