import { QueryFn } from '@angular/fire/firestore';
import { Observable } from 'rxjs/internal/Observable';
import { map,distinct } from 'rxjs/operators';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { Plan } from './plan';
import { Address, IAddressData } from './address';
import { Configuration,IConfigurationData } from './configuration';
import { PaymentOptions,IPaymentOptionsData } from './payment_options';
import { IRestaurantData } from './restaurant-data.interface';
import { Table } from './table';
import { ITableData } from './table-data.interface';

import { TableSession } from './table-session';
import { ITableSessionData } from './table-session-data.interface';
import { ITableLocationData, TableLocation } from './table-locations';

export class Restaurant extends Model<IRestaurantData> {
  public static type = 'restaurants';

  constructor(data: Partial<IRestaurantData>, id: string | null = null, parentPath?: string) {
    const defaultData: IRestaurantData = {
      createdAt: null,
      name: '',
      type: 'restaurant',
      updatedAt: null,
      userId: '',
      personalTeam: false,
      id: null,
      planId: null,
      subscriptionId: null,
      planExpiresAt: null,
      planExpired: false,
      addressId:null,
      websiteId:null
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
   return this.rawData;
  }

  _tables$: Observable<Table[]> | undefined;

  get tables$(): Observable<Table[]> {
    if (!this._tables$) {
      this._tables$ = this.odm()
        .child<ITableData>('tables', (ref) => {
          return ref.orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((tablesSnap) => {
            return tablesSnap.map(({ payload }) => {
              return Table.fromPayloadDocument(payload.doc);
            });
          })
        );
    }
  
    return this._tables$;
  }
  _booking_tables$: Observable<Table[]> | undefined;

  get booking_tables$(): Observable<Table[]> {
    if (!this._booking_tables$) {
      this._booking_tables$ = this.odm()
        .child<ITableData>('tables', (ref) => {
          return ref.orderBy('seats', 'asc');
        })
        .snapshotChanges()
        .pipe(
          
          map((tablesSnap) => {
            return tablesSnap.map(({ payload }) => {
              return Table.fromPayloadDocument(payload.doc);
            });
          }),
          distinct(e => this.data.seats),
        );
    }
  
    return this._booking_tables$;
  }

  private _plan$: Observable<Plan | null> | undefined;

  get plan$(): Observable<Plan | null> {
    if (!this._plan$) {
      this._plan$ = new Plan({}, this.data.planId)
        .odm()
        .doc()
        .snapshotChanges()
        .pipe(
          map((snap) => {
            return Plan.fromDocumentChange(snap.payload);
          })
        );
    }
    
    return this._plan$;
  }


  private _address$: Observable<Address[] | null> | undefined;

  get address$(): Observable<Address[] | null> {
    if (!this._address$) {
      
      this._address$ = this.odm()
      .child<IAddressData>('addresses', (ref) => {
            return ref.orderBy('updatedAt', 'desc');
          })
        .snapshotChanges()
        .pipe(
          map((snap) => {
            return snap.map(({ payload }) => {
              return Address.fromPayloadDocument(payload.doc);
            });
          })
        );
    }
   
    return this._address$;
  }

  _configuration$: Observable<Configuration[]> | undefined;

  get configuration$(): Observable<Configuration[]> {
    if (!this._configuration$) {
      this._configuration$ = this.odm()
        .child<IConfigurationData>('configurations', (ref) => {
          return ref.orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((tablesSnap) => {
            return tablesSnap.map(({ payload }) => {
              return Configuration.fromPayloadDocument(payload.doc);
            });
          })
        );

    }
  
    return this._configuration$;
  }

  _tableLocation$: Observable<TableLocation[]> | undefined;

  get tableLocation$(): Observable<TableLocation[]> {
    if (!this._tableLocation$) {
      this._tableLocation$ = this.odm()
        .child<ITableLocationData>('table_locations', (ref) => {
          return ref.orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((tablesSnap) => {
            return tablesSnap.map(({ payload }) => {
              return TableLocation.fromPayloadDocument(payload.doc);
            });
          })
        );

    }
  
    return this._tableLocation$;
  }
  _paymentOptions$: Observable<PaymentOptions[]> | undefined;

  get paymentOptions$(): Observable<PaymentOptions[]> {
    if (!this._paymentOptions$) {
      this._paymentOptions$ = this.odm()
        .child<IPaymentOptionsData>('payment_options', (ref) => {
          return ref.orderBy('updatedAt', 'desc');
        })
        .snapshotChanges()
        .pipe(
          map((tablesSnap) => {
            return tablesSnap.map(({ payload }) => {
              return PaymentOptions.fromPayloadDocument(payload.doc);
            });
          })
        );

    }
  
    return this._paymentOptions$;
  }

  /**
   * Obtiene un stream con las sesiones.
   */
  sessions$(ref?: QueryFn<ITableSessionData>) {
    return this.odm()
      .child<ITableSessionData>('table_sessions', ref)
      .snapshotChanges()
      .pipe(
        map((tablesSnap) => {
          return tablesSnap.map(({ payload }) => {
            return TableSession.fromPayloadDocument(payload.doc);
          });
        })
      );
  }

  /**
   * Obtiene un stream con los cambios hechos en las sesiones del restaurant.
   *
   * Permite construir una lista mutable de elementos según vayan cambiando.
   */
  public tableSessionsStateChanges$(ref?: QueryFn<ITableSessionData>) {
    return this.odm().child<ITableSessionData>('table_sessions', ref).stateChanges();
  }
}
