import { Dictionary } from 'lodash';
import * as _ from 'lodash-es';
import { IJsonMap } from '~lib/types';

export const convertKeysToCamelCase = (obj: { [key: string]: any }, options: { recursive?: boolean } = {}) => {
  const recursiveObjectKeys: string[] = [];
  const recursiveArrayKeys: string[] = [];

  const newObj = _.mapKeys(obj, (value, key) => {
    const newKey = key.toCamelCase();

    if (options.recursive && !!value) {
      // Agregarlos a la lista para procesarlos luego
      if (Array.isArray(value)) {
        recursiveArrayKeys.push(newKey);
      } else if (typeof value === 'object') {
        recursiveObjectKeys.push(newKey);
      }
    }

    return newKey;
  });

  // Convertir recursivamente
  recursiveObjectKeys.forEach((key) => {
    newObj[key] = convertKeysToCamelCase(newObj[key], options);
  });

  recursiveArrayKeys.forEach((key) => {
    const newArray = (newObj[key] as any[]).map((val) => {
      return convertKeysToCamelCase(val, options);
    });

    newObj[key] = newArray;
  });

  return newObj;
};

export const convertKeysToSnakeCase = (obj: Dictionary<any>, options: { recursive?: boolean } = {}) => {
  const recursiveObjectKeys: string[] = [];
  const recursiveArrayKeys: string[] = [];

  const newObj = _.mapKeys(obj, (value, key) => {
    const newKey = key.toSnakeCase();

    if (options.recursive && !!value) {
      // Agregarlos a la lista para procesarlos luego
      if (Array.isArray(value)) {
        recursiveArrayKeys.push(newKey);
      } else if (typeof value === 'object') {
        recursiveObjectKeys.push(newKey);
      }
    }

    return newKey;
  });

  // Convertir recursivamente
  recursiveObjectKeys.forEach((key) => {
    newObj[key] = convertKeysToSnakeCase(newObj[key], options);
  });

  recursiveArrayKeys.forEach((key) => {
    const newArray = (newObj[key] as any[]).map((val) => {
      return convertKeysToSnakeCase(val, options);
    });

    newObj[key] = newArray;
  });

  return newObj;
};

/**
 * Convierte un objeto plano en su representación como query string para URLs.
 *
 * @author Nelson Martell <nelson6e65@gmail.com>
 */
export const objectToQueryString = (params: IJsonMap, parentKey?: string): string => {
  const queryString = Object.keys(params)
    .map((key) => {
      let q = '';
      const keyString = parentKey ? `${parentKey}[${key}]` : key;
      const value = params[key];

      if (_.isNil(value) || value === '') {
        // No incluir cadenas vacías ni null
        return q;
      }

      if (_.isArray(value)) {
        const values = value.map((val) => {
          return keyString + '=' + val;
        });

        q = values.join('&');
      } else if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        q = keyString + '=' + value.toString();
      } else {
        q = objectToQueryString(value, key);
      }

      return q;
    })
    .filter((param) => {
      return param.length > 1;
    })
    .join('&');

  return queryString;
};

/**
 * Convierte un valor a booleano el valor de un atributo HTML.
 *
 * Compatible con `true`, `false`, `undefined` y las cadenas `''`, `'on'`, `'true'` (true) y `off`, `'false'`. (false).
 *
 * Su mera presencia asume que es `true`.
 *
 * @author Nelson Martell <nelson6e65@gmail.com>
 */
export const normalizeBooleanAttribute = (value: string | boolean | undefined): boolean => {
  let val: boolean;

  // Verificar conversión al establecerlo desde markup
  if (typeof value === 'string') {
    const valueAsString = value.toLowerCase().trim();

    val = valueAsString === '' || valueAsString === 'true' || valueAsString === 'on';
  } else {
    val = value === true || value === undefined;
  }

  return val;
};

/**
 * Obtiene la data completa de un objeto parcial, con sus valores predeterminados.
 */
export const defaultedData = <T extends { [key: string]: any }>(partialData: Partial<T>, defaultData: T): T => {
  const safeData = _.omitBy(partialData, (key, val) => {
    return _.isEmpty(val);
  });

  const fullData = _.defaults(_.pick(safeData, Object.keys(defaultData)), defaultData);

  return fullData as T;
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * Detecta si el evento especificado es un evento personalizado.
 */
export const isCustomEvent = <T = any>(event: Event): event is CustomEvent<T> => {
  return 'detail' in event;
};
