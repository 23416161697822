import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { IImageData } from './image-data.interface';
export interface IConfigurationData extends IFirebaseData {
  id: string | null;
  userId: string;
  teamId:string;
  squareimage: IImageData | null;
  circleimage: IImageData | null;
  timeIntervals:{} | null;
  enabled:boolean | false;
  isOpen:string | null;
  city:string;
  address:string;
  showhide:boolean | false;
  latitude:string | null;
  longitude:string | null;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}
/**
 * Representa un plan que agrupa módulos.
 */
export class Configuration extends Model<IConfigurationData> {
  public static type = 'configurations';

  constructor(data: Partial<IConfigurationData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IConfigurationData = {
      id: null,
      userId: '',
      teamId:'',
      squareimage: null,
      circleimage: null,
      enabled:false,
      showhide:true,
      city:'',
      address:'',
      isOpen:'',
      timeIntervals:null,
      latitude:'',
      longitude:'',
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

 
}
