import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import Model from '~lib/database/model';

import { ITableData } from './table-data.interface';
import { TableSession } from './table-session';
import { User } from './user';

/**
 * Representa una mesa en la base de datos.
 */
export class Table extends Model<ITableData> {
  public static type = 'tables';

  constructor(data: Partial<ITableData>, id: string | null = null, parentPath?: string) {
    const {
      teamId = '',
      label = '',
      seats = null,
      tableStatusId = 1,
      tableLocationId = 1,
      userId = null,
      createdAt = null,
      updatedAt = null,
      deletedAt = null,
    } = data;

    if (!parentPath && !!teamId) {
      parentPath = `restaurants/${teamId}`;
    }

    super(
      {
        id: data.id ?? null,
        teamId,
        label,
        seats,
        tableLocationId,
        tableStatusId,
        userId,
        available: data.available ?? true,
        enabled: data.available ?? false,
        createdAt,
        updatedAt,
        deletedAt,
      },
      id,
      parentPath
    );
  }

  get data() {
    return this.rawData;
  }

  private _sessions$: Observable<TableSession[]> | undefined;

  public get sessions$(): Observable<TableSession[]> {
    if (!this._sessions$) {
      this._sessions$ = new TableSession({}, undefined, this.parentPath)
        .odm()
        .collection((ref) => {
          return ref.where('tableId', '==', this.id);
        })
        .snapshotChanges()
        .pipe(
          map((sessionsSnap) => {
            return sessionsSnap.map(({ payload }) => {
              return new TableSession(payload.doc.data(), payload.doc.id, this.parentPath);
            });
          })
        );
    }

    return this._sessions$;
  }

  private _session$: Observable<TableSession | null> | undefined;

  /**
   * Get active session, if any.
   */
  public get session$(): Observable<TableSession | null> {
    if (!this._session$) {
      this._session$ = new TableSession({}, undefined, this.parentPath)
        .odm()
        .collection((ref) => {
          return ref.where('tableId', '==', this.id).where('closed', '==', false);
        })
        .snapshotChanges()
        .pipe(
          map((sessionSnap) => {
            if (sessionSnap.length < 1) {
              return null;
            }

            const snap = sessionSnap[0];

            return new TableSession(snap.payload.doc.data(), snap.payload.doc.id, this.parentPath);
          })
        );
    }

    return this._session$;
  }

  private _user$: Observable<User | null> | undefined;

  /**
   * Get asigned attendant, if any.
   */
  public get user$(): Observable<User | null> {
    if (!this._user$) {
      this._user$ = new User({}, this.data.userId)
        .odm()
        .doc()
        .valueChanges()
        .pipe(
          map((user) => {
            if (!user) {
              return null;
            }

            return new User(user, this.data.userId);
          })
        );
    }

    return this._user$;
  }
}
