import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subscription } from 'rxjs';
import { distinct } from 'rxjs/operators';
import { normalizeBooleanAttribute } from '~lib/helpers';

export interface FormInputInputs {
  /**
   * Control de formulario
   */
  control?: AbstractControl | FormControl;
  faIcon?: IconProp;
  label?: string;
  color?: string;
  disabled: boolean;
  help?: string;
}

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit, OnDestroy {
  public readonly inputs: FormInputInputs = {
    disabled: false,
  };

  @Input()
  set disabled(value: boolean | string | undefined) {
    this.inputs.disabled = normalizeBooleanAttribute(value);
  }

  @Input()
  set color(value: string | undefined) {
    this.inputs.color = value || undefined;
  }

  @Input()
  set label(value: string | undefined) {
    this.inputs.label = value || '';
  }

  @Input()
  set help(value: string | undefined) {
    this.inputs.help = value || '';
  }

  @Input()
  set control(value: AbstractControl | FormControl | undefined) {
    this.inputs.control = value;

    this.resetControlSubscription();

    if (value !== undefined) {
      this.subscribeToControlErrorsChange();
    }
  }

  @Input()
  lines: string | undefined;

  @Input()
  innerClass: string | undefined;

  @Input()
  set faIcon(value: IconProp | undefined) {
    if (!value) {
      this.inputs.faIcon = undefined;

      return;
    }

    if (typeof value === 'string') {
      value = ['fas', value];
    } else if (Array.isArray(value)) {
      if (value.length < 2) {
        value = ['fas', value[0] as IconName];
      } else {
        value = [value[0], value[1]];
      }
    }

    this.inputs.faIcon = value;
  }

  get errors(): string[] {
    if (this.inputs.control && Array.isArray(this.inputs.control.errors)) {
      return this.inputs.control.errors as string[];
    }
    return [];
  }

  private onControlValueChangesSubscription: Subscription | undefined | null;

  constructor(private zone: NgZone) {
    //
  }

  ngOnInit() {
    //
  }

  ngOnDestroy() {
    this.resetControlSubscription();
  }

  resetControlSubscription() {
    if (this.onControlValueChangesSubscription) {
      if (!this.onControlValueChangesSubscription.closed) {
        this.onControlValueChangesSubscription.unsubscribe();
      }
    }
  }

  subscribeToControlErrorsChange() {
    if (!this.inputs.control) {
      return;
    }

    // Limpiar el error al cambiar el valor del control
    this.onControlValueChangesSubscription = this.inputs.control.valueChanges.pipe(distinct()).subscribe((val) => {
      // TODO: Revisar impacto en el desempeño al suscribirse a cada formulario
      this.zone.run(() => {
        if (this.inputs.control?.errors) {
          this.inputs.control.clearValidators();
          this.inputs.control.updateValueAndValidity();
        }
      });
    });
  }
}
