import { Observable } from 'rxjs';
import { Relationships } from '~lib/database/firestore/relationships';

import { Plan } from './plan';
import { Restaurant } from './restaurant';
import { Subscription } from './subscription';
import { ISubscriptionData } from './subscription.data';

export class SubscriptionRelationships extends Relationships<Subscription, ISubscriptionData> {
  private _plan$: Observable<Plan | null> | undefined;
  private _restaurant$: Observable<Restaurant | null> | undefined;

  public get plan$() {
    if (!this._plan$) {
      this._plan$ = this.plan();
    }

    return this._plan$;
  }

  public get restaurant$() {
    if (!this._restaurant$) {
      this._restaurant$ = this.restaurant();
    }

    return this._restaurant$;
  }

  public plan() {
    return this.belongsTo(Plan, 'planId');
  }

  public restaurant() {
    return this.belongsTo(Restaurant, 'teamId');
  }
}
