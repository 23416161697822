import * as moment from 'moment';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

export interface IAreaData extends IFirebaseData {
  createdAt: string | null;
  description: string | null;
  id: string | null;
  title: string;
  updatedAt: string | null;
}

/**
 * Representa a un área de atención para las categorías.
 */
export class Area extends Model<IAreaData> {
  public static type = 'areas';

  constructor(data: Partial<IAreaData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IAreaData = {
      createdAt: null,
      id: null,
      updatedAt: null,
      description: null,
      title: '',
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }
}
