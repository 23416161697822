import { Injector } from '@angular/core';

/**
 *
 *
 * @link https://stackoverflow.com/a/58323112/7242535
 */
export class ServiceLocator {
  static injector: Injector;
}
