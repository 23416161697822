import { HttpParams } from '@angular/common/http';
import { ApiForm } from '~lib/helpers/api-form';
import { IJsonMap } from '~lib/types';
import { UriRoute } from '~shared/services/laravel-api.service';

export interface SearchProductFiltersFormData extends IJsonMap {
  text: string;
}

export interface SearchProductFormData extends IJsonMap {
  filters: SearchProductFiltersFormData;
}

/**
 * Formulario para validar los filtros de búsqueda
 */
export class SearchProductFiltersForm extends ApiForm<SearchProductFiltersFormData> {
  constructor(initialData: Partial<SearchProductFiltersFormData> = {}) {
    super(
      {
        method: 'GET',
        uri: new UriRoute('products'),
      },
      initialData,
      ['category_id', 'code', 'name', 'description', 'text']
    );
  }
}

/**
 * Formulario para aplicar filtros de búsqueda para productos.
 */
export class SearchProductForm extends ApiForm<SearchProductFormData> {
  constructor(restaurantId: string, initialData?: Partial<SearchProductFormData>) {
    if (!initialData) {
      initialData = {
        filters: {
          category_id: '',
          code: '',
          description: '',
          name: '',
          text: '',
        },
      };
    }

    super(
      {
        method: 'GET',
        uri: new UriRoute('teams/{restaurant}/products', { restaurant: restaurantId }),
        queryParams: new HttpParams({ fromObject: { with: 'stock' } }),
      },
      {},
      []
    );

    this.addControl('filters', new SearchProductFiltersForm(initialData.filters));
  }

  get filtersForm(): SearchProductFiltersForm {
    return this.controls.filters as SearchProductFiltersForm;
  }
}
