const spacesAndSlashesForTrimRegex = /^(\/|\s)+|(\s|\/)+$/g;

/**
 * Metadatos de una colección.
 */
export class CollectionMetadata {
  /**
   * @param name   Nombre de la colección en la base de datos.
   * @param parent Ruta padre de la colección. Opcional: por defecto es la raíz.
   */
  constructor(public readonly name: string, public readonly parent?: string) {
    this.name = name.replace(spacesAndSlashesForTrimRegex, '');
    this.parent = parent?.replace(spacesAndSlashesForTrimRegex, '');

    if (!name) {
      throw new Error('Empty or whitespaces `name` for the collection is not valid');
    }
  }

  path(): string {
    let path = this.name;

    if (this.parent) {
      path = `${this.parent}/${path}`;
    }

    return path;
  }

  toString(): string {
    return this.path();
  }
}
