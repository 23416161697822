import { Component, Input, OnInit,ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OrderProduct } from '~/database/models/order-product';
import { IOrderProductData } from '~/database/models/order-product-data.interface';
import { Product } from '~/database/models/product';
import { IRestaurantData } from '~/database/models/restaurant-data.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { NavController,ToastController,LoadingController } from '@ionic/angular';
import {
  LaravelApiService,
 resultIsCollection,
 UriRoute,
} from '~shared/services/laravel-api.service';
import { Restaurant } from '~/database/models/restaurant';
import { distinct, map, share,first } from 'rxjs/operators';
import { OrderDriver } from '~/database/models/order-driver';
import { User } from '~/database/models/user';
import { IUserData } from '~/database/models/user.interface';

export interface AddReviewData {
  befastercomments: string;
  attendantcomments: string;
  drivercomments: string;
  prodcutcomments:{},
  befasterRating: number;
  driverRating: number;
  attendantRating: number;
  productRating:Array<{itemId: string, itemRating: number}>; 
  orderId:string;
  driverId:string;
  attendantId:string;
}

export type OrderItem = { product?: Observable<Product | null> } & Pick<
  IOrderProductData,
  'price' | 'quantity' | 'notes' | 'offerApplicable' | 'promocode' | 'promoquantity'
>;


@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.component.html',
  styleUrls: ['./rating-modal.component.scss'],
})
export class RatingModalComponent implements OnInit {
  #orderProducts: OrderProduct[] = [];
  #orderTeamId:string='';
  #attendantId:string='';
  #orderId:string='';
  #driver : Observable<OrderDriver | null | undefined> | undefined ;
  driver_id:string='';
  attendant_id:string='';
  form: FormGroup;
  driverName:string='';
  driverId:string | null='';
  befastercomments:string='';
  attendantcomments:string='';
  drivercomments:string='';
  prodcutcomments:any=  {};
  
  befasterRating:number=0;
  driverRating:number=0;
  attendantRating:number=0;
  productRating:  Array<{itemId: string, itemRating: number}> = []; 

 // restaurant$: Observable<Restaurant | null | undefined>;
 private restaurants$: Observable<Restaurant | null | undefined> | undefined;
 private user$: Observable<User | null | undefined> | undefined;
  @Input()
  set orderTeamId(orderTeamId) {
    this.#orderTeamId = orderTeamId
  }
  @Input()
  set attendantId(attendantId) {
    this.#attendantId = attendantId
  }
  @Input()
  set orderId(orderId) {
    this.#orderId = orderId
  }
 
  
  @Input()
  set orderProducts(products) {
    this.#orderProducts = products;
  }  
  @Input()
  set driver(drivers$) {   
    this.#driver = drivers$;
  }

  
  get orderId() {
     return this.#orderId;
  }
  get orderTeamId() {
     return this.#orderTeamId;
  }
  get attendantId() {
    return this.#attendantId;
 }
  get orderProducts() {
   
    return this.#orderProducts;
  }
  get driver() {   
    return this.#driver;
  }

  items: OrderItem[] = [];
  total = 0;

  constructor(
    public modalController: ModalController,
     private api: LaravelApiService,
     private toastCtrl: ToastController,
     private route: ActivatedRoute,
     private navCtrl: NavController,
     private loadingCtrl: LoadingController,
     private el: ElementRef
     ) {
      console.log(this.orderId)
    this.form = new FormGroup({
      befastercomments: new FormControl(),
      attendantcomments: new FormControl(),
      drivercomments: new FormControl(),  
      prodcutcomments: new FormControl(),  
    });
  }
  async confirm(orderId:string) {
     
    // const array = Object.keys(this.prodcutcomments).filter((key)=> {return this.prodcutcomments[key].textvalue});
    // console.log(array)

    this.befastercomments = this.form.controls.befastercomments.value;
    this.attendantcomments = this.form.controls.attendantcomments.value;
    this.drivercomments = this.form.controls.drivercomments.value;
    this.prodcutcomments = JSON.stringify(this.prodcutcomments);

 //   this.commment = this.form.controls.comments.value;
    const data: AddReviewData = {
      befastercomments: this.befastercomments,
      attendantcomments: this.attendantcomments,
      drivercomments: this.drivercomments,
      prodcutcomments:this.prodcutcomments,
      befasterRating: this.befasterRating,
      driverRating: this.driverRating,
      attendantRating: this.attendantRating,
      productRating: this.productRating,
      orderId:orderId,
      driverId:this.driver_id,
      attendantId:this.attendant_id
    };
    if(this.befasterRating == 0){
      this.showToast('Please Provide Befaster APP Rating')
    }
    else if(this.driverRating == 0 && this.driverId){
      this.showToast('Please Provide Driver Rating')
    }
    else if(this.attendantRating == 0 && this.attendantId){
      this.showToast('Please Provide Attendant Rating')
    }
    else if(this.productRating.length == 0){
      this.showToast('Please Provide Product Rating')
    }
    else{
      this.modalController.dismiss(data, 'submit');
    }
  } 
  showToast(mesg:string){
    this.toastCtrl.create({
      message: mesg,
      duration: 3000,
      position: 'top',
      color:'danger'
    })
    .then((toast) => {
      toast.present();
    });
  }
  ngOnInit() {
    this.total = 0;
    this.items = this.#orderProducts.map((orderProduct) => {
      const item: OrderItem = {
        price: +orderProduct.data.price,
        quantity: orderProduct.data.quantity,
        notes: orderProduct.data.notes,
        offerApplicable: orderProduct.data.offerApplicable,
        promocode: orderProduct.data.promocode,
        promoquantity: orderProduct.data.promoquantity,
      };
    
      const ref = orderProduct.restaurantRef();
     
      if (ref) {
        const p = new Product({}, orderProduct.data.productId, ref.path);

        const p$ = p
          .odm()
          .doc()
          .get()
          .pipe(
            map((r) => {
              const data = r.data();
              // console.log(r);

              if (!data) {
                return null;
              }

              return new Product(data, r.id, ref.path);
            })
          );

        item.product = p$;
      }else{
        
        if(this.orderTeamId){
          const p = new Product({}, orderProduct.data.productId, `restaurants/${this.#orderTeamId}`);
          //restaurants/${restaurant.id}/order/${data.id}
        const p$ = p
          .odm()
          .doc()
          .get()
          .pipe(
            map((r) => {
              const data = r.data();
              // console.log(r);

              if (!data) {
                return null;
              }

              return new Product(data, r.id, `restaurants/${this.#orderTeamId}`);
            })
          );

        item.product = p$;
        }
      }
      
      if(this.#driver){
        this.#driver.subscribe((driver:any)=>{
          if(driver && driver.length > 0)
          { 
            const customer$ = new User({}, driver[0].data.userId)
          .odm()
          .doc()
          .get()
          .pipe(
            map((snapshot) => {
              return new User(snapshot.data(), driver[0].data.userId);
            })).subscribe((user)=>{
              if(user){
                this.driverName = user.data.name;
                this.driverId = user.data.id;
              }
            });
            
          }
         
        })
      }
      
      if(this.orderTeamId){
        this.restaurants$ = this.api
        .get<IRestaurantData>(new UriRoute(`teams/${this.orderTeamId}`))
        .pipe(first(),map((res) => {
          if (!res.data || resultIsCollection(res)) {
            return null;
          }
          return new Restaurant(res.data, res.data.id);
        }));

      }
      if(this.attendantId){
        this.user$ = new User({}, this.attendantId)
        .odm()
        .doc()
        .get()
        .pipe(
          map((snapshot) => {
            return new User(snapshot.data(), this.attendantId);
          })
        );
      }

      if (item.promoquantity) {
        this.total += +item.price * item.promoquantity;
      } else {
        this.total += +item.price * item.quantity;
      }

      return item;
    });
   
    console.log(this.items)
  }
  onRatingChange(rating:number,component:string,itemId:string){
       
    switch (component) {
      case 'products':
        this.productRating.push({itemId:itemId,itemRating:rating})
        document.getElementById(itemId)?.classList.remove("d-none");
        document.getElementById(itemId)?.classList.add("d-block");
        break;

      case 'restaurant':
        this.attendantRating = rating;
        this.attendant_id = itemId;
        document.getElementById('restaurantRating')?.classList.remove("d-none");
        document.getElementById('restaurantRating')?.classList.add("d-block");
        break;

      case 'befaster':
        this.befasterRating = rating;
        document.getElementById('befasterRating')?.classList.remove("d-none");
        document.getElementById('befasterRating')?.classList.add("d-block");
        break;
       
      case 'driver':
        this.driverRating = rating;
        this.driver_id = itemId;

        document.getElementById('driverRating')?.classList.remove("d-none");
        document.getElementById('driverRating')?.classList.add("d-block");
        break; 
    }
    
  }

  public dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }
  showProductReviewBox(productid:string){
     if(productid){
      document.getElementById(productid)?.classList.remove("d-none");
      document.getElementById(productid)?.classList.add("d-block");
    }
    
  }
}
