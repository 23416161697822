import { Observable } from 'rxjs';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { map } from 'rxjs/operators';
import { IProductData } from './product-data.interface';
import { Promotion } from './promotion';
import { IPromotionData } from './promotion-data.interface';
export class Product extends Model<IProductData> {
  public static type = 'products';

  constructor(data: Partial<IProductData>, id: string | null = null, parentPath?: string) {
    const defaultData: IProductData = {
      id,
      categoryId: '',
      code: '',
      name: '',
      description: null,
      image: null,
      price: 0,
      availableStock: 0,
      promotionId: null,
      ingredients: [],
      extraIngredients: [],
      promotion: null,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    const safeData = defaultedData(data, defaultData);
    super(safeData, id ?? data.id ?? null, parentPath);
  }

  /**
   * Obtiene la cantidad de productos disponibles.
   */
  public get availableStock(): number {
    return this.data.availableStock;
  }
  public get promotionId(): any {
    return this.data.promotionId;
  }

  get data() {
    return this.rawData;
  }
}
