import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';
import { Order } from './order';
import { IOrderData } from './order-data.interface';
import { IUserData } from './user.interface';
import { Observable, of, Subscription } from 'rxjs';
import { QueryFn } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { UserAddress } from './user-address';
import { IUserAddressData } from './user-address-data.interface';
/**
 * Representa a un usuario.
 */
export class User extends Model<IUserData> {
  public static type = 'users';

  constructor(data: Partial<IUserData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IUserData = {
      name: '',
      deletedAt: null,
      createdAt: null,
      email: null,
      id: null,
      profilePhotoUrl: null,
      updatedAt: null,
      gender: null,
      regType:null,
      openTableSession:null,
      currentRestaurantId:null,
      userStatus:null
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
   
    return this.rawData;
  }

  private _orders$: Observable<Order | null> | undefined;

  get orders(): Observable<Order | null> {
    if (!this._orders$) {
      this._orders$ = new Order({}, this.data.id)
        .odm()
        .doc()
        .get()
        .pipe(
          map((snapshot) => {
            const data = snapshot.data();

            if (!snapshot.exists || !data) {
              return null;
            }

            return new Order(data, this.data.id);
          })
        );
    }

    return this._orders$;
  }

 
  _addresses$: Observable<UserAddress[]> | undefined;

  get addresses$(): Observable<UserAddress[]> {
    if (!this._addresses$) {
      this._addresses$ = this
        .odm()
         .child<IUserAddressData>('user_addresses')
        .snapshotChanges()
        .pipe(
          map((addresses) => {
            return addresses.map((address ) => {
              return UserAddress.fromPayloadDocument(address.payload.doc);
            });
          })
        );
    }

    return this._addresses$;
  }


 /* private _orders$: Observable<Order[]> | undefined;
  get orders$() {
    if (!this._orders$) {
      this._orders$ = this.getOrders$((ref) => {
        return ref.orderBy('updatedAt', 'desc');
      });
    }

    return this._orders$;
  }
  getOrders$(filters?: QueryFn<IOrderData>) {
    const orders$ = this.odm()
      .child<IOrderData>('orders', filters)
      .snapshotChanges()
      .pipe(
        map((snaps) => {
          return snaps.map(({ payload }) => {
            return Order.fromPayloadDocument(payload.doc);
          });
        })
      );
      console.log(orders$);
    return orders$;
  }*/
}
