import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash-es';
import { ModalController } from '@ionic/angular';
import { object } from 'firebase-functions/v1/storage';
import { Product } from '~/database/models/product';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { trigger, state, style, animate, transition, query, animateChild } from '@angular/animations';


@Component({
    selector: 'app-modal [view="payment-modal"]',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.scss'],
    animations: [
      trigger('ngIfAnimation', [transition(':enter, :leave', [query('@*', animateChild())])]),
      trigger('easeInOut', [
        transition('void => *', [
          style({
            opacity: 0,
          }),
          animate(
            '500ms ease-in',
            style({
              opacity: 1,
            })
          ),
        ]),
        transition('* => void', [
          style({
            opacity: 1,
          }),
          animate(
            '500ms ease-in',
            style({
              opacity: 0,
            })
          ),
        ]),
      ]),
    ],
  })
export class PaymentModalComponent {
    @Input() firstName: string | undefined;
    @Input() lastName: string | undefined;
    @Input() middleInitial: string | undefined;
    @Input() imageUrl:string | undefined;
  constructor(public modalController: ModalController) {}
  public dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }
  
}