import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import IFirebaseData from '~lib/database/firebase-data.interface';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

import { FeatureWithPivot, IFeatureWithPivotData } from './plan.feature-with-pivot';

export interface IPlanData extends IFirebaseData {
  id: string | null;
  title: string;
  description: string | null;
  price: string | number;
  yearly_price: string | number;
  public: boolean;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}

/**
 * Representa un plan que agrupa módulos.
 */
export class Plan extends Model<IPlanData> {
  public static type = 'plans';

  constructor(data: Partial<IPlanData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IPlanData = {
      id: null,
      title: '',
      description: null,
      price: 0,
      yearly_price: 0,
      public: false,
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
    return this.rawData;
  }

  private _features$: Observable<FeatureWithPivot[]> | undefined;

  /**
   * Lista de módulos habilitados para el plan.
   */
  get features$(): Observable<FeatureWithPivot[]> {
    if (!this._features$) {
      this._features$ = this.odm()
        .child<IFeatureWithPivotData>(FeatureWithPivot.type)
        .snapshotChanges()
        .pipe(
          map((modules) => {
            return modules.map((p) => {
              return FeatureWithPivot.fromPayloadDocument(p.payload.doc);
            });
          })
        );
    }

    return this._features$;
  }
}
