import { AngularFirestore, QueryFn } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { ServiceLocator } from '~lib/helpers/service-locator';

import IFirebaseData from '../firebase-data.interface';

import { CollectionMetadata } from './collection-metadata';

/**
 * Abstracción de acceso a AngularFirestore para una instancia de un modelo.
 */
export class CloudFirestoreOdm<T extends IFirebaseData = IFirebaseData> {
  static afsInstance: AngularFirestore | undefined;

  static get afs() {
    if (!this.afsInstance) {
      this.afsInstance = ServiceLocator.injector.get(AngularFirestore);
    }

    return this.afsInstance;
  }

  static afsRootDocument: string | undefined;

  /**
   * Documento raíz para agrupar por colección de servidores
   * @return [description]
   */
  static get rootDocument(): string {
    if (!this.afsRootDocument) {
      this.afsRootDocument = `servers/${environment.laravel.api.domain}`;
    }

    return this.afsRootDocument;
  }

  protected readonly metadata: CollectionMetadata;

  /**
   *
   * @param collectionInfo Metadatos de la colección.
   * @param collectionInfo.name Nombre de la colección en la base de datos.
   * @param collectionInfo.parent Ruta padre de la colección (optional).
   */
  constructor(collectionInfo: { name: string; parent?: string }, protected readonly id: string | null = null) {
    this.metadata = new CollectionMetadata(collectionInfo.name, collectionInfo.parent);
  }

  /**
   * Obtiene una referencia a la colección a la que pertenece la instancia.
   *
   * Permite realizar operaciones en la colección padre.
   */
  public collection(queryFn?: QueryFn<T>) {
    const self = this.constructor as typeof CloudFirestoreOdm;

    const path = this.metadata.path().startsWith(self.rootDocument)
      ? this.metadata.path()
      : `${self.rootDocument}/${this.metadata.path()}`;

    return self.afs.collection<T>(path, (queryFn as unknown) as QueryFn<firebase.firestore.DocumentData>);
  }

  /**
   * Obtiene una referencia al documento en Firebase, si el ID de la instancia está establecido.
   *
   * Permite realizar operaciones en el documento.
   */
  public doc() {
    return this.collection().doc(this.id ?? undefined);
  }

  /**
   * Obtiene una referencia al documento padre en Firebase de la instancia.
   */
  public parent<P extends IFirebaseData = IFirebaseData>() {
    return this.collection().ref.parent as firebase.firestore.DocumentReference<P> | null;
  }

  /**
   * Obtiene una referencia a una subcolección del documento actual.
   */
  public child<C extends IFirebaseData = IFirebaseData>(collectionName: string, queryFn?: QueryFn<C>) {
    return this.doc().collection<C>(collectionName, (queryFn as unknown) as QueryFn | undefined);
  }
}
