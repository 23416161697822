import { Component,  Input, OnInit } from '@angular/core';
import _ from 'lodash-es';
import { ModalController } from '@ionic/angular';
import { first} from 'rxjs/operators';
import { trigger, style, animate, transition, query, animateChild } from '@angular/animations';
import { LaravelApiService, UriRoute } from '~shared/services/laravel-api.service';
import { ITableData } from '~/database/models/table-data.interface';
import { DomSanitizer } from '@angular/platform-browser';

 @Component({
  selector: 'app-modal [view="qr-code"]',
  templateUrl: './qr-code-modal.component.html',
  styleUrls: ['./qr-code-modal.component.scss'],
  animations: [
    trigger('ngIfAnimation', [transition(':enter, :leave', [query('@*', animateChild())])]),
    trigger('easeInOut', [
      transition('void => *', [
        style({
          opacity: 0,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition('* => void', [
        style({
          opacity: 1,
        }),
        animate(
          '500ms ease-in',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class QrCodeModalComponent implements OnInit {
  
  #tableId:string = '';
  data:any;
  step:any;
  @Input()
  set tableId(value) {
    this.#tableId = value;
  }

  get tableId() {
    return this.#tableId;
  }

  constructor(public modalController: ModalController, private api: LaravelApiService,private sanitizer:DomSanitizer) {
      console.log(this.tableId)
  }
 
  async ngOnInit() {
   
      console.log(this.tableId)
      const uri = new UriRoute('tables/{table}/qr-code', { table: this.tableId });
      this.data =  await this.api.get<ITableData>(uri).pipe(first()).toPromise();
      this.step=this.sanitizer.bypassSecurityTrustHtml(`${this.data?.data?.svg}`)
      console.log(this.data.url)
  }
  public dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }
   

   
}
