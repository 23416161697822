import envsjson from 'src/environments/cache/envs.json';
import { JsonValue } from '~lib/types';

const ENV: JsonValue = envsjson ?? {};

// export interface EnvOptions {
//   /**
//    * Indica si la variable de entorno debe estar definida, aunque pueda estar vacía
//    */
//   required?: boolean;
// }
type IOverload = {
  (name: string): JsonValue | undefined;
  <T = JsonValue>(name: string, defaultValue: T): T;
};

/**
 * Obtiene el valor de una variable de entorno.
 *
 * @param name Nombre de la variable de entorno.
 * @param defaultValue Valor predeterminado si no está definido en el .env
 *
 * @author Nelson Martell <nelson6e65@gmail.com>
 */
export const env: IOverload = (
  name: string,
  defaultValue?: JsonValue
  // options: EnvOptions = { required: false }
): JsonValue | undefined => {
  // if (options.required && ENV[name] === undefined) {
  //   throw new Error(`'${name}' environment is not defined`);
  // }

  return ENV[name] ?? defaultValue;
};
