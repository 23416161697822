import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Order } from '~/database/models/order';
import { OrderProduct } from '~/database/models/order-product';
import { IOrderStatusData, OrderStatus } from '~/database/models/order-status';
import { AuthenticationService } from '~shared/services/authentication.service';
import { OverlayEventDetail } from '@ionic/core';
import { OrderProductsModalComponent } from '../order-products-modal/order-products-modal.component';
import { RatingModalComponent } from '../rating-modal/rating-modal.component';
import { AddReviewData } from '../rating-modal/rating-modal.component';
import {
  LaravelApiService,
 resultIsCollection,
 UriRoute,
} from '~shared/services/laravel-api.service';
import { ActivatedRoute } from '@angular/router';
import { OrderDriver } from '~/database/models/order-driver';
@Component({
  selector: 'app-order-card-customer',
  templateUrl: './order-card-customer.component.html',
  styleUrls: ['./order-card-customer.component.scss'],
})
export class OrderCardCustomerComponent implements OnInit, OnDestroy {
  #statuses: IOrderStatusData[] = [];
  currentUserType:string | null | undefined;
  @Input()
  order: Order | undefined;

  @Input()
  products: OrderProduct[] = [];

  private _products$: Observable<OrderProduct[]> | undefined;

  @Input()
  set products$(products$: Observable<OrderProduct[]> | undefined) {
    if (this.productsSub && !this.productsSub.closed) {
      this.productsSub.unsubscribe();
    }

    if (products$) {
      this.productsSub = products$.subscribe((products) => {
        this.products = products;
      });
    } else {
      this.products = [];
    }

    this._products$ = products$;
  }
  get products$() {
    return this._products$;
  }

  private productsSub: Subscription | undefined;

  @Input()
  set statuses(statuses) {
    this.#statuses = statuses.sort((a, b) => {
      return a.value - a.value;
    });
  }
  get statuses() {
    return this.#statuses;
  }

  get status(): OrderStatus {
    const f = this.statuses.find((item) => {
      return item.id === this.order?.data.orderStatusId;
    }) || {
      slug: 'waiting',
      title: 'Waiting',
      value: 0,
      createdAt: null,
      description: null,
      id: null,
      updatedAt: null,
    };

    return new OrderStatus(f);
  }

  get progressValue() {
    
    if(this.status.value == 12){
      return 25/100;
    }
    if(this.status.value == 13){
      return 25/100;
    }
   if(this.status.value == 14){
      return 50/100;
    }
    if(this.status.value == 15){
      return 75/100;
    }
    //return this.status.value / this.progressTotal;
  }

  get progressTotal() {
    return 100;
  }

  get total() {
    let t = 0;

    this.products.forEach((item) => {
      if (item.data.promoquantity) {
        t += item.data.promoquantity * +item.data.price;
      } else {
        t += item.data.quantity * +item.data.price;
      }
    });

    return t;
  }

  @Output()
  public readonly billClick = new EventEmitter<string>();

  
  @Output()
  public readonly cardClick = new EventEmitter<string>();

  @Output()
  public readonly confirmClick = new EventEmitter<string>();


  /**
   * Permite detectar si hay algún hadler del evento cardClick asignado.
   */
  get hasCardClickHandlers() {
    // console.log('🚧', this.clicked.observers.length);
    return this.cardClick.observers.length > 0;
  }

  constructor(
    private modalController: ModalController,
    private api: LaravelApiService,
    private auth:AuthenticationService ) {
     
      
    this.auth.currentUserInstance$.subscribe((user)=>{
      this.currentUserType = user?.data.regType;
    })
  
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.productsSub && !this.productsSub.closed) {
      this.productsSub.unsubscribe();
    }
  }

  public async showProductsModal(ev: Event) {
    console.log(this.order?.data.teamId)
    ev.stopPropagation();
    const modal = await this.modalController.create({
      component: OrderProductsModalComponent,
      swipeToClose: true,
      componentProps: {
        orderProducts: this.products,
        orderTeamId : this.order?.data.teamId,
        
      },
    });

    await modal.present();
  }

  onBillClick(ev: Event) {
    ev.stopPropagation();

    const billId = this.order?.data.billId;
    // console.log('⭐ onBillClick()', billId);

    if (!billId) {
      return;
    }

    this.billClick.emit(billId);
  }
  
  public async ratingComponent(ev: Event,orderId:String,drivers$:OrderDriver) {
    ev.stopPropagation();
    const modal = await this.modalController.create({
      component: RatingModalComponent,
      swipeToClose: true,
      componentProps: {
        orderProducts: this.products,
        orderTeamId : this.order?.data.teamId,
        orderId:orderId,
        driver:drivers$
      },
    });

    await modal.present();


    const result: OverlayEventDetail<AddReviewData> = await modal.onDidDismiss();

   try {
    
      if(result.data){
        const uri = new UriRoute('orders/addreviews');
        const res = await this.api
          .post(uri, 
            { 
              userId:this.auth.currentUser?.uid,
              orderTeamId:this.order?.data.teamId,
              driverId:result.data?.driverId,
              orderId:result.data?.orderId,
              attendantId:result.data?.attendantId,
              befastercomments: result.data?.befastercomments,
              attendantcomments: result.data?.attendantcomments,
              prodcutcomments:result.data?.prodcutcomments,
              drivercomments: result.data?.drivercomments,
              driverRating: result.data?.driverRating,
              befasterRating: result.data?.befasterRating,
              attendantRating: result.data?.attendantRating,
              productRating: result.data?.productRating
            }
          )
          .pipe(first())
          .toPromise();
          if(res){
           
            
          }
      }
      
      
    } catch (err:any) {
     
      
    }
  }

 
  
  onCardClick() {
    const orderId = this.order?.id;
    // console.log('⭐ onCardClick()');

    if (!orderId) {
      return;
    }
    
    this.cardClick.emit(orderId);
  }


  onConfirmClick(ev: Event){
    ev.stopPropagation();
    const orderId = this.order?.id;
    if (!orderId) {
      return;
    }
    this.confirmClick.emit(orderId);
  }
  onOrderStatusClick(ev: Event) {
    ev.stopPropagation();
  }
}
