import firebase from 'firebase/app';
import * as _ from 'lodash-es';
import { stringify } from 'querystring';
import { Observable } from 'rxjs';
import { map, tap,first } from 'rxjs/operators';
import Model from '~lib/database/model';
import { convertKeysToCamelCase, defaultedData } from '~lib/helpers';

import { IOrderDriverData } from './order-driver-data.interface';

import { Product } from './product';
import { IRestaurantData } from './restaurant-data.interface';
import { Order } from './order';
import {IOrderData } from './order-data.interface';
import { LaravelApiService } from '~shared/services/laravel-api.service';

/**
 * Representa los detalles de un pedido.
 */
export class OrderDriver extends Model<IOrderDriverData> {
  public static type = 'order_drivers';
  currentUserOrder: any;
  public teamId : string | undefined;
  public localVar:Order | null | undefined;
  static apiService: LaravelApiService | undefined;

  public current_usr: IOrderData | undefined;

  constructor(data: Partial<IOrderDriverData> = {}, id: string | null = null, parentPath?: string,) {
    const defaultData: IOrderDriverData = {
      createdAt: null,
      id: null,
      orderId: '',
      userId: '',
      driverStatus:'',
      updatedAt: null,
     
    };

    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);

    const params = {
      order: data.orderId ,
    };
  }

  get data() {
    
    return this.rawData;
  }

}
