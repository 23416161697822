import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, first, switchMap, timeout } from 'rxjs/operators';
import { AuthenticationService } from '~shared/services/authentication.service';

export interface AuthGuardParams {
  /**
   * Determina qué tipo de usuario se permite.
   *
   * Si es true, se intentará detectar automáticamente.
   */
  checkIsType?: 'customer' | 'regcustomer' | 'attendant' |  'driver' | 'any' | boolean;

  /**
   * Tiempo de espera en milisegundos para obtener un valor definido para el observable de autenticación
   * (ya se null o un usuario) .
   *
   * Por defecto, 5 segundos (5000 ms).
   */
  timeout?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(protected readonly auth: AuthenticationService, protected router: Router) {
    //
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const options = (route.data.authGuardParams as AuthGuardParams) || {};
    
    if (options.checkIsType === true) {
      const intendedUrlBase = state.url.replace(/^(\/|\s)+|(\s|\/)+$/g, '').split('/')[0] ?? '';

      if (intendedUrlBase === 'attendant' || intendedUrlBase === 'customer') {
        options.checkIsType = intendedUrlBase;
      } else {
        console.warn('Unable to auto-detect user type based in the intended url:', intendedUrlBase);
        options.checkIsType = false;
      }
    } else {
      if (typeof options.checkIsType === 'string') {
        if (!['customer','regcustomer', 'attendant','driver', 'any'].includes(options.checkIsType)) {
          console.error('Specified user type is not valid:', options.checkIsType);

          return of(false);
        }
      }
    }

    options.timeout = options.timeout ?? 5000;

    let result: Observable<boolean | UrlTree>;

    if (options.checkIsType) {
      // Verificación avanzada del tipo de usuario
      result = this.auth.currentUserType$.pipe(
        filter((r) => r !== undefined),
        timeout(options.timeout),
        first(),
        switchMap((r) => {
          // console.log(r);
          if (!r) {
            return of(this.getCanNotActivateRouteResponse(route, options));
          }

          return of(
            options.checkIsType === 'any' ||
              r === options.checkIsType ||
              this.getCanNotActivateRouteResponse(route, options)
          );
        }),
        catchError((err) => {
          // FIXME: Autenticar primero
          console.error('Error al capturar el usuario actual', err);
          return of(this.getCanNotActivateRouteResponse(route, options));
        })
      );
    } else {
      // Verificación simple del usuario
      result = this.auth.currentUser$.pipe(
        filter((r) => r !== undefined),
        timeout(options.timeout),
        first(),
        switchMap((r) => {
          // console.log(r);
          if (!r) {
            return of(this.getCanNotActivateRouteResponse(route, options));
          }

          return of(true);
        }),
        catchError((err) => {
          // FIXME: Autenticar primero
          console.error('Error al capturar el usuario actual', err);
          return of(this.getCanNotActivateRouteResponse(route, options));
        })
      );
    }

    return result;
  }

  /**
   * Gets the redirection url if user is unable to activate the route. If can't be detected the user type, it will return false.
   *
   */
  protected getCanNotActivateRouteResponse(
    route: ActivatedRouteSnapshot,
    options: AuthGuardParams
    //
  ): UrlTree | boolean {
    let url: string;

    switch (options.checkIsType) {
      case 'attendant':
        url = `/auth/login/email`;
        break;
      case 'driver':
          url = `/auth/login/email`;
          break;

      case 'customer':
        url = `/auth/login/anonymous`;
        break;
      
      case 'regcustomer':
          url = `/auth/login/anonymous`;
          break;
  
      case false:
      case undefined:
        url = '/?';
        break;

      default:
        url = 'auth/unauthorized';
    }

    const params = new HttpParams({ fromObject: route.queryParams });

    return this.router.parseUrl(`${url}?${params.toString()}`);
  }
}
