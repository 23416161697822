import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

import { IOrderData } from './order-data.interface';
import { OrderProduct } from './order-product';
import { OrderDriver } from './order-driver';
import { IOrderProductData } from './order-product-data.interface';
import { IOrderDriverData } from './order-driver-data.interface';
import { User } from './user';
import { OrderPayment } from './order-payment';
import { IOrderPaymentData } from './order-payment-data.interface';

/**
 * Representa una mesa en la base de datos.
 */
export class Order extends Model<IOrderData> {
  public static type = 'orders';

  constructor(data: Partial<IOrderData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: IOrderData = {
      attendantId: null,
      billedAt: null,
      billId: null,
      createdAt: null,
      customerId: '',
      deletedAt: null,
      id: null,
      orderStatusId: null,
      driverStatusId: null,
      tableSessionId: '',
      updatedAt: null,
      label: '',
      notes: '',
      offerApplicable: false,
      promocode: '',
      promoquantity: 0,
      teamId:'',
      deliveryFee:0,
      orderType:'',
      orderAddressType:'',
      orderAddressImage:'',
      orderAddressTime:'',
      paymentMethodType:'',
      image: null,
      reviewGiven:false
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  get data() {
      return this.rawData;
  }

  public orderProductsCount: number | undefined;

  private _items$: Observable<OrderProduct[]> | undefined;
  get items$(): Observable<OrderProduct[]> {
    if (!this._items$) {
      // console.log(this.odm().child<IOrderProductData>('order_products').ref.path);
     
      
      this._items$ = this.odm()
        .child<IOrderProductData>('order_products')
        .get()
        .pipe(
          map((products) => {
            this.orderProductsCount = products.size;

            return products.docs.map((p) => {
              return OrderProduct.fromPayloadDocument(p);
            });
          })
        );
    }

    return this._items$;
  }


  public orderDriversCount: number | undefined;

  private _driver$: Observable<OrderDriver[]> | undefined;
  get drivers$(): Observable<OrderDriver[]> {
    if (!this._driver$) {
      // console.log(this.odm().child<IOrderProductData>('order_products').ref.path);
     
      
      this._driver$ = this.odm()
        .child<IOrderDriverData>('order_drivers')
        .get()
        .pipe(
          map((drivers) => {
            this.orderDriversCount = drivers.size;
           
            return drivers.docs.map((p) => {
              return OrderDriver.fromPayloadDocument(p);
            });
          })
        );
    }

    return this._driver$;
  }

  public orderPaymentCount: number | undefined;

  private _payment$: Observable<OrderPayment[]> | undefined;
  get payments$(): Observable<OrderPayment[]> {
    if (!this._payment$) {
      // console.log(this.odm().child<IOrderProductData>('order_products').ref.path);
     
      
      this._payment$ = this.odm()
        .child<IOrderPaymentData>('order_payment')
        .get()
        .pipe(
          map((payments) => {
            this.orderPaymentCount = payments.size;
           
            return payments.docs.map((p) => {
              return OrderPayment.fromPayloadDocument(p);
            });
          })
        );
    }

    return this._payment$;
  }


  private _orderProducts$: Observable<OrderProduct[]> | undefined;
  /**
   * Obtiene un stream de los cambios que ocurran en los items (a diferencia de items$ que sólo los trae una vez)
   */
  get orderProducts$(): Observable<OrderProduct[]> {
    if (!this._orderProducts$) {
      this._orderProducts$ = this.odm()
        .child<IOrderProductData>('order_products')
        .snapshotChanges()
        .pipe(
          map((products) => {
            return products.map(({ payload }) => {
              return OrderProduct.fromPayloadDocument(payload.doc);
            });
          })
        );
    }

    return this._orderProducts$;
  }

  private _customer$: Observable<User> | undefined;
  get customer$(): Observable<User> {
    if (!this._customer$) {
      this._customer$ = new User({}, this.data.customerId)
        .odm()
        .doc()
        .get()
        .pipe(
          map((snapshot) => {
            return new User(snapshot.data(), this.data.customerId);
          })
        );
    }

    return this._customer$;
  }

  private _attendant$: Observable<User | null> | undefined;
  get attendant$(): Observable<User | null> {
    if (!this._attendant$) {
      this._attendant$ = new User({}, this.data.attendantId)
        .odm()
        .doc()
        .get()
        .pipe(
          map((snapshot) => {
            const data = snapshot.data();

            if (!data) {
              return null;
            }

            return new User(data, this.data.attendantId);
          })
        );
    }

    return this._attendant$;
  }
}
