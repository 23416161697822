import { Observable } from 'rxjs';
import { Relationships } from '~lib/database/firestore/relationships';

import { Plan } from './plan';
import { Restaurant } from './restaurant';
import { IRestaurantData } from './restaurant-data.interface';
import { Subscription } from './subscription';

export class RestaurantRelationships extends Relationships<Restaurant, IRestaurantData> {
  private _subscription$: Observable<Subscription | null> | undefined;
  private _plan$: Observable<Plan | null> | undefined;

  public get plan$() {
    if (!this._plan$) {
      this._plan$ = this.plan();
    }

    return this._plan$;
  }

  public get subscription$() {
    if (!this._subscription$) {
      this._subscription$ = this.subscription();
    }

    return this._subscription$;
  }

  public plan() {
    return this.belongsTo(Plan, 'planId');
  }

  public subscription() {
    return this.belongsTo(Subscription, 'subscriptionId');
  }
}
