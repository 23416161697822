import { Component, NgZone, OnDestroy, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { filter, first, map, share  } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';
import { FaConfig } from '@fortawesome/angular-fontawesome';
import { NavController,AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from '~shared/services/authentication.service';
import { SessionService } from '~shared/services/session.service';
import { ThemeService } from '~shared/services/theme.service';
import { runInZone } from '~lib/helpers/rxjs-operators';
import { environment } from 'src/environments/environment';
import { Restaurant } from './database/models/restaurant';
import { IRole } from './database/models/role.interface';
import { User } from './database/models/user';
import { ITableData } from '~/database/models/table-data.interface';
import { ITableSessionData } from '~/database/models/table-session-data.interface';
import { TableSession } from '~/database/models/table-session';
import {
  ILaravelApiCollectionResult,
  ILaravelApiSingleResult,
  LaravelApiService,
  resultIsCollection,
  UriRoute,
} from '~shared/services/laravel-api.service';

const { App, Device } = Plugins;
const convertResultToInstanceForTable = (
  result: ILaravelApiSingleResult<ITableSessionData> | ILaravelApiCollectionResult<ITableSessionData>
) => {
   
  if (resultIsCollection(result)) {
   
    return result.data.map((tableData) => {
     
      return new TableSession(tableData, tableData.id);
    });
  }

  throw new Error('Invalid response');
};


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  
})
export class AppComponent implements OnInit, OnDestroy {
  public restaurant$: Observable<Restaurant | null | undefined>;
  currentUserRole: IRole | null | undefined;
  showButton = true;
  showthisButton = true;
  activeTableSession:string|undefined;
  showQRButton = true;
  public get isDark() {
    return this.theme.isDark;
  }
  public set isDark(value) {
    this.theme.isDark = value;
  }

  selectedLanguage: 'en' | 'es' | string | undefined;

  public currentUser: User | null | undefined;
  public openSession:any | null | undefined;
  protected readonly subscriptions = new Subscription();
  tablesSession$: Observable<TableSession[]> | undefined | null;


  constructor(
    private auth: AuthenticationService,
    private restaurantService: SessionService,
    private navCtrl: NavController,
    
    private alertCtrl:AlertController,
    private router: Router,
    private zone: NgZone,
    public theme: ThemeService,
    private api: LaravelApiService,
    private translate: TranslateService,
    faConfig: FaConfig,
    private ref: ChangeDetectorRef
  ) {
   
    this.theme.isDark = true;//window.matchMedia('(prefers-color-scheme: light)').matches;
    faConfig.fixedWidth = true;
    
    this.restaurant$ = restaurantService.restaurant$;
     
    
    const userRoleSub = this.auth.role$.subscribe((role) => {
       console.log('👨🏻‍🍳', role);
       this.currentUserRole = role;
       if (!this.auth.currentUser?.uid) {
          console.error('No user id');
          return;
        }
        this.tablesSession$ = this.api
       
         .get<ITableSessionData>(new UriRoute('list-current-table/{userid}', { userid: this.auth.currentUser.uid }))
         .pipe(share(), map(convertResultToInstanceForTable),runInZone(this.zone),);
          
         this.tablesSession$.subscribe((table)=>{
          if(table){
            this.activeTableSession = table[0]?.data.tableId;
            console.log(this.activeTableSession)
          }
         
          
          
         });
    });

    
    this.getRestaurant();
   

  }

  async showAlert(){
    const userdata = await this.auth.currentUserInstance$
    .pipe(
      filter((s) => s !== undefined),
      first()
    )
    .toPromise();
    console.log(userdata?.data.id)
    const alert = await this.alertCtrl.create({
      header: 'Access Table Session',
      cssClass: 'custom-alert',
      message: ' You have an active table session you can access the table session in the table session area or click yes to go to your table session',
      buttons: [
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: async () => {
            await this.navCtrl.navigateRoot('/regcustomer/table/'+userdata?.data.openTableSession);
        
          },
        },
        {
          text: 'Not now',
          cssClass: 'alert-button-cancel',
          role: 'dismiss',
          handler: async () => {
             console.log('Not Now')
          },
        },
      ],
    });
    //await alert.present();
  }

  
  public ngOnInit() {

   
    this.initializeApp();
    this.initTranslate();

    this.theme.scheme$.subscribe((newSheme) => {
      document.body.classList.toggle('dark', newSheme === 'dark');
    });
    const userSub = this.auth.currentUserInstance$.subscribe((user) => {
       console.log('💀', user);
      if (user != null || user != undefined) {
        this.showButton = false;
      }
      this.currentUser = user;
      
   
     });
     this.subscriptions.add(userSub);
   
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected initializeApp() {
    
    // Configurar deep links
    // https://capacitorjs.com/docs/guides/deep-links#deep-link-routing-using-the-capacitor-app-api
    App.addListener('appUrlOpen', (data) => {
      this.zone.run(() => {
        // console.log('Navegando', data.url);
        const slug = data.url.split('.skyliner.cl').pop();
        if (slug) {
        
          
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
    
    
    
  }

  async initTranslate() {
    const deviceLocale = (await Device.getLanguageCode()).value;

    // console.log(deviceLocale);

    if (deviceLocale.startsWith('es')) {
      this.changeLanguage('es');
    } else {
      this.changeLanguage('en');
    }
  }

  onSelectedLanguage(event: Event) {
    const { value } = (event as CustomEvent<{ value: string }>).detail;

    this.changeLanguage(value);
  }

  changeLanguage(lang: 'en' | 'es' | string) {
    if (this.selectedLanguage === undefined) {
      this.selectedLanguage = lang;
    }
    // console.log(lang);
    return this.translate.use(lang);
  }

  async signOut() {
    await this.auth.signOut();
    await this.goTo('/', 'root');
  }
  async redirectAndGoTo(path: string) {
   
    const alert = await this.alertCtrl.create({
      header: 'Log In',
      cssClass: 'custom-alert',
      message: 'Are you sure you want to leave current session ? if you proceed your current session will be lost. you need to create a new cart but your order will be sync.',
      buttons: [
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: async () => {
            await this.auth.signOut();
            await this.goTo(path, 'root');
        
          },
        },
        {
          text: 'Not now',
          cssClass: 'alert-button-cancel',
          role: 'dismiss',
          handler: async () => {
             console.log('Not Now')
          },
        },
      ],
    });
    await alert.present();
    
  } 

  async leaveTable(tableId:string){
   
    const alert = await this.alertCtrl.create({
      header: 'Leave Table',
      cssClass: 'custom-alert',
      message: 'Would you like to leave table right now?',
      buttons: [
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: async () => {
            const uri = new UriRoute('table/{table}/removeUser', { table: tableId });
            await this.api.post<ITableData>(uri, { userId: this.auth.currentUser?.uid }).pipe(first()).toPromise();
            await this.zone.run(async () => {
             this.auth.currentUserInstance$.subscribe((user) => {
               this.currentUser = user;
             });
             this.showthisButton = false;
             this.showQRButton =false;
              window.location.assign('/regcustomer/home');
           //  await this.navCtrl.navigateRoot(['/regcustomer/home']);
            })
          },
        },
        {
          text: 'Not now',
          cssClass: 'alert-button-cancel',
          role: 'dismiss',
          handler: async () => {
             console.log('Not Now')
          },
        },
      ],
    });
    await alert.present();
    
  }
  
  async goToMainMenu(path: string, direction: 'root' | 'forward' | 'backward' = 'forward') {
    this.restaurantService.clear();
   //  window.location.reload();

    await this.zone.run(async () => {
      
      // window.location.reload();
      switch (direction) {
        case 'backward':
          await this.navCtrl.navigateBack([path]);
          break;

        case 'forward':
          await this.navCtrl.navigateForward([path]);
          break;

        case 'root':
          await this.navCtrl.navigateRoot([path]);
          break;
      }
    });
  } 

  async goTo(path: string, direction: 'root' | 'forward' | 'backward' = 'forward') {
    await this.zone.run(async () => {
      
      this.zone.runOutsideAngular(() => {
        setTimeout(async() => {
          window.location.href = `${environment.laravel.api.mainUrl}auth/registration/${path}/aerosmith`;
        }, 25);
      });

      // switch (direction) {
      //   case 'backward':
      //     await this.navCtrl.navigateBack([path]);
      //     break;

      //   case 'forward':
      //     await this.navCtrl.navigateForward([path]);
      //     break;

      //   case 'root':
      //     await this.navCtrl.navigateRoot([path]);
      //     break;
      // }
    });
  }
  async getRestaurant(){
    const restaurant = await this.restaurantService.restaurant$
    .pipe(
      filter((s) => s !== undefined),
      first()
    )
    .toPromise();
    this.openSession = restaurant?.data.name;
    return restaurant?.data.name;
  }
}
