import * as moment from 'moment';
import Model from '~lib/database/model';
import { defaultedData } from '~lib/helpers';

import { ISubscriptionData } from './subscription.data';

/**
 */
export class Subscription extends Model<ISubscriptionData> {
  public static type = 'subscriptions';

  constructor(data: Partial<ISubscriptionData> = {}, id: string | null = null, parentPath?: string) {
    const defaultData: ISubscriptionData = {
      id: null,
      planId: '',
      teamId: '',
      endsAt: null,
      price: 0,
      startsAt: null,
      createdAt: null,
      updatedAt: null,
    };

    // Eliminar los ya predeterminados
    const safeData = defaultedData(data, defaultData);

    super(safeData, id ?? data.id ?? null, parentPath);
  }

  public get startsAt() {
    if (typeof this.rawData.startsAt !== 'string') {
      return null;
    }

    return moment(this.rawData.startsAt);
  }

  public get endsAt() {
    if (typeof this.rawData.endsAt !== 'string') {
      return null;
    }

    return moment(this.rawData.endsAt);
  }
}
